import { Paper, Typography, Grid } from "@mui/material";
import {
    Timeline, TimelineItem, TimelineSeparator, TimelineConnector, TimelineContent, TimelineDot, TimelineOppositeContent, timelineOppositeContentClasses
} from "@mui/lab";
import dayjs from "dayjs";
import { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context/UserContext";
import { axiosInstance } from "../../utils/utils";

const Activities = () => {
    const [data, setData] = useState([]);
    const [userContext, setUserContext] = useContext(UserContext);

    useEffect(() => {
        const fetchData = () => {
            const url = process.env.REACT_APP_API_URL + "/inventory/recentactivities";
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            axiosInstance.get(url, config).then(res => setData(res.data)).catch(err => { });
        };

        fetchData();
    }, [userContext, setUserContext]);

    return (
        <Paper
            sx={{
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                height: "100%",
                padding: "25px",
                boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)",
            }}
        >
            <Typography align="center" sx={{ fontSize: "18px" }}>Activities</Typography>
            <Grid container item sx={{ height: "100%", paddingBottom: "25px", overflow: "hidden" }}>
                <Timeline
                    sx={{
                        "&.MuiTimeline-root": {
                            display: "block",
                            height: "100%",
                            mask: "linear-gradient(to bottom, rgba(0, 0, 0, 1) 0, rgba(0, 0, 0, 1) 40%, rgba(0, 0, 0, 0) 95%, rgba(0, 0, 0, 0) 0) 100% 50% / 100% 100% repeat-x"
                        },
                        [`& .${timelineOppositeContentClasses.root}`]: { flex: 0.2 }
                    }}
                >
                    {data.map((activity, index) => {
                        let text = "";
                        if (activity.type === "checkout") {
                            text = activity.person + " checked out (" + activity.quantity + ") " + activity.description;
                        } else {
                            text = "Restocked (" + activity.quantity + ") " + activity.description;
                        }

                        return (
                            <TimelineItem key={index}>
                                <TimelineOppositeContent color="textSecondary">{dayjs(activity.date).format("hh:mm A")}</TimelineOppositeContent>
                                <TimelineSeparator>
                                    <TimelineDot />
                                    <TimelineConnector />
                                </TimelineSeparator>
                                <TimelineContent>
                                    {text}
                                </TimelineContent>
                            </TimelineItem>
                        );
                    })}
                </Timeline>
            </Grid>
        </Paper>
    );
};

export default Activities;