import { Typography, Grid } from "@mui/material";

const TableSummary = props => {
    return (
        <Grid container wrap="nowrap" alignItems={"baseline"}>
            <Grid container item alignItems="center" justifyContent="flex-start" sx={{ mr: 2.5 }}>
                <Grid item>
                    <Typography variant="h5">{props.title}</Typography>
                </Grid>
            </Grid>
            <Grid container item>{props.totalComponent}</Grid>
        </Grid>
    );
};

export default TableSummary;