import { Grid, Chip } from "@mui/material";

const ColumnSelector = props => {
    const handleChipClick = column => props.setSelectedColumns({ ...props.selectedColumns, [column]: !props.selectedColumns[column] });

    return (
        <Grid container item spacing={2.5}>
            {props.columns.map((column, index) => {
                return (
                    <Grid item key={index}>
                        <Chip label={column} variant={props.selectedColumns[column] ? "filled" : "outlined"} onClick={() => handleChipClick(column)} />
                    </Grid>
                );
            })}
        </Grid>
    );
};

export default ColumnSelector;