import { Grid, Button } from "@mui/material";
import EntryFields from "./EntryFields";
import { useEffect, useRef } from "react";

const EntriesFields = props => {
    const setEntries = props.setEntries;
    const entries = props.entries;
    const buttonRef = useRef();
    const lastCodeRef = useRef();
    const itemAutoAdded = useRef(false);

    const handleChange = (index, event) => {
        let updatedEntries = [...entries];
        updatedEntries[index][event.target.name] = event.target.value;
        setEntries(updatedEntries);
    };

    const handleAdd = () => { setEntries(entries => [...entries, { code: "", quantity: 1, description: "" }]) };

    const handleRemove = index => {
        let updatedEntries = [...entries];
        updatedEntries.splice(index, 1);
        setEntries(updatedEntries);
    };

    useEffect(() => {
        const listenForTabOnItem = () => {
            const checkTabOnItem = event => {
                if (event.code === "Tab" && document.activeElement === buttonRef.current) {
                    event.preventDefault();
                    itemAutoAdded.current = true;
                    setEntries(entries => [...entries, { code: "", quantity: 1, description: "" }]);
                }
            };

            document.addEventListener("keyup", checkTabOnItem);

            return () => { document.removeEventListener("keyup", checkTabOnItem) };
        };

        listenForTabOnItem();
    }, [setEntries]);

    useEffect(() => {
        if (itemAutoAdded.current) {
            itemAutoAdded.current = false;
            lastCodeRef.current.focus();
        }
    }, [entries]);

    return (
        <>
            {entries.map((entry, index) => {
                return (
                    <EntryFields
                        code={entry.code || ""}
                        description={entry.description}
                        quantity={entry.quantity || ""}
                        handleChange={handleChange}
                        handleRemove={handleRemove}
                        data={props.data}
                        index={index}
                        key={index}
                        inventoryData={props.inventoryData}
                        codeRef={index === entries.length - 1 ? lastCodeRef : null}
                        options={props.options}
                    />
                )
            })}
            <Grid container item><Button onClick={handleAdd} ref={buttonRef}>Add Entry</Button></Grid>
        </>
    );
};

export default EntriesFields;