import { Grid } from "@mui/material";
import Title from "../components/Common/Title";
import DashboardCard from "../components/Dashboard/DashboardCard";
import Activities from "../components/Dashboard/Activities";
import LowStocks from "../components/Dashboard/LowStocks";
import LastCheckout from "../components/Dashboard/LastCheckout";
import Greeting from "../components/Dashboard/Greeting";
import MonthlyExpensesChart from "../components/Dashboard/MonthlyExpensesChart";
import { useEffect } from "react";
import { useOutletContext } from "react-router-dom";

const Dashboard = () => {
    const cardGridParams = { sx: { width: "33.3%" } };
    const startDate = new Date(new Date().toDateString()).toISOString().slice(0, 10);
    const endDate = new Date(new Date().toDateString()).toISOString().slice(0, 10);
    const cardHeight = "30vh";
    const [, setRightSidebar] = useOutletContext();

    useEffect(() => {
        setRightSidebar({ open: false });
    }, [setRightSidebar]);

    return (
        <div>
            <Title title="Dashboard" />
            <Grid container spacing={2.5} sx={{ width: "100%" }}>
                <Grid container item><Greeting /></Grid>
                <Grid container item spacing={5}>
                    <Grid item {...cardGridParams}>
                        <DashboardCard
                            backgroundColor="#8bacaa"
                            heading="Items"
                            description="Items checked out today"
                            url={process.env.REACT_APP_API_URL + "/inventory/totalcheckouts?start_date=" + startDate + "&end_date=" + endDate}
                            height={cardHeight}
                        />
                    </Grid>
                    <Grid item {...cardGridParams}>
                        <DashboardCard
                            backgroundColor="#e76161"
                            heading="Expense"
                            description="Total expense today"
                            url={process.env.REACT_APP_API_URL + "/inventory/totalexpense?start_date=" + startDate + "&end_date=" + endDate}
                            height={cardHeight}
                        />
                    </Grid>
                    <Grid item {...cardGridParams}>
                        <DashboardCard
                            backgroundColor="#f99b7d"
                            heading="Inventory"
                            description="Items added today"
                            url={process.env.REACT_APP_API_URL + "/inventory/totalstocks?start_date=" + startDate + "&end_date=" + endDate}
                            height={cardHeight}
                        />
                    </Grid>
                </Grid>
                <Grid container item><LastCheckout /></Grid>
                <Grid container item spacing={5} sx={{ minHeight: "625px", height: "45vh" }}>
                    <Grid item sx={{ width: "66.6%", height: "100%" }}><LowStocks /></Grid>
                    <Grid item sx={{ width: "33.3%", height: "100%" }}><Activities /></Grid>
                </Grid>
                <Grid container item sx={{ minHeight: "475px", height: "45vh" }}><MonthlyExpensesChart /></Grid>
            </Grid>
        </div>
    );
};

export default Dashboard;