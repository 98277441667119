import { Grid, TextField, IconButton, Tooltip, Autocomplete, createFilterOptions } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";

const AddEntryFields = props => {
    const filterOptions = createFilterOptions({ matchFrom: "start" });
    const textFieldParams = { type: "text", fullWidth: true, variant: "outlined" };

    const handleChange = (name, value) => {
        const event = { "target": { "name": name, "value": value } };
        props.handleChange(props.index, event)
    };

    const handleCodeChange = value => {
        handleChange("code", value);

        const item = props.inventoryData.find(item => item.code.toLowerCase() === value.toLowerCase());

        if (item) {
            handleChange("description", item.description);

            if (props.price === "") {
                handleChange("price", Number.parseFloat(item.price).toFixed(2));
            }
        } else {
            handleChange("description", "");
        }
    };

    const handleDescriptionChange = value => {
        handleChange("description", value);

        const item = props.inventoryData.find(item => item.description.toLowerCase() === value.toLowerCase());

        if (item) {
            handleChange("code", item.code);

            if (props.price === "") {
                handleChange("price", Number.parseFloat(item.price).toFixed(2));
            }
        } else {
            handleChange("code", "");
        }
    };

    return (
        <Grid container item spacing={2.5}>
            <Grid container item sx={{ width: "20%" }}>
                <Autocomplete
                    options={props.options.allCodes}
                    renderInput={params => (<TextField {...params} label="Code" inputRef={props.codeRef} />)}
                    onInputChange={(event, value) => handleCodeChange(value)}
                    inputValue={String(props.code)}
                    freeSolo
                    fullWidth
                    disableClearable
                    autoSelect
                    autoComplete
                    autoHighlight
                    filterOptions={filterOptions}
                />
            </Grid>
            <Grid container item sx={{ width: "13%" }}>
                <TextField
                    {...textFieldParams}
                    name="quantity"
                    label="Qty."
                    value={String(props.quantity)}
                    onChange={event => handleChange(event.target.name, event.target.value)}
                />
            </Grid>
            <Grid container item sx={{ width: "17%" }}>
                <TextField
                    {...textFieldParams}
                    name="price"
                    label="Unit $"
                    value={String(props.price)}
                    onChange={event => handleChange(event.target.name, event.target.value)}
                />
            </Grid>
            <Grid container item sx={{ width: "40%" }}>
                <Tooltip title={props.description}>
                    <Autocomplete
                        options={props.options.allDescriptions}
                        renderInput={params => (<TextField {...params} label="Description" inputProps={{ ...params.inputProps, tabIndex: -1 }} />)}
                        onInputChange={(event, value) => handleDescriptionChange(value)}
                        inputValue={String(props.description)}
                        freeSolo
                        fullWidth
                        disableClearable
                        autoSelect
                        autoComplete
                        autoHighlight
                    />
                </Tooltip>
            </Grid>
            {props.handleRemove ?
                <Grid container item alignItems="center" sx={{ width: "10%" }}>
                    {props.index ? <IconButton tabIndex={-1} onClick={() => props.handleRemove(props.index)}><CloseIcon /></IconButton> : null}
                </Grid>
                :
                null
            }
        </Grid>
    );
};

export default AddEntryFields;