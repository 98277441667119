import { Button, IconButton, Grid } from "@mui/material";
import { useState, useEffect, useContext, useRef } from "react";
import dayjs from "dayjs";
import FieldsOutline from "../../Common/FieldsOutline";
import DetailsFields from "../Fields/DetailsFields";
import DeleteEntryDialog from "../../Common/DeleteEntryDialog";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ErrorBox from "../../Common/ErrorBox";
import { UserContext } from "../../../context/UserContext";
import { axiosInstance } from "../../../utils/utils";

const EditMultipleModalComponent = props => {
    const [entry, setEntry] = useState(
        {
            _id: "",
            category: "",
            code: "",
            description: "",
            unit: "",
            price: "",
            minimumQuantity: "",
            productNumber: "",
            voc: null,
            grouping: ""
        }
    );

    const [image, setImage] = useState();
    const [entries, setEntries] = useState([]);
    const [disableButton, setDisableButton] = useState(true);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [userContext, setUserContext] = useContext(UserContext);
    const [openDelete, setOpenDelete] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const loaded = useRef(false);
    const multipleValues = "(Multiple Values)";

    const handleSubmit = event => {
        event.preventDefault();
        setDisableButton(true);

        let updatedEntries = [];

        entries.forEach(fetchedEntry => {
            let updatedEntry = { _id: fetchedEntry._id };

            Object.entries(entry).forEach(([key, value]) => {
                if (value === multipleValues || (value === null && key === "voc")) {
                    updatedEntry[key] = fetchedEntry[key];
                } else {
                    updatedEntry[key] = value;
                }
            });

            if (entry.image) {
                updatedEntry.image = entry.image;
            } else {
                updatedEntry.image = fetchedEntry.image;
            }

            updatedEntries.push(updatedEntry);
        });

        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };
        let promises = [];

        updatedEntries.forEach(updatedEntry => {
            const url = process.env.REACT_APP_API_URL + "/inventory/items/" + updatedEntry._id;
            const body = updatedEntry;

            body.code = body.code.toLowerCase();

            promises.push(axiosInstance.postForm(url, body, config));
        });

        Promise.all(promises)
            .then(res => {
                props.setEditMultiple({ ...props.editMultiple, "open": false });
                props.setSnackbarMessage("Inventory items updated");
                props.setOpenSnackbar(true);
                props.setUpdating(true);
            })
            .catch(err => {
                setErrorMessage(err.response.data);
                setShowErrorMessage(true);
                setDisableButton(false);
            });
    };

    const handleDelete = () => {
        let promises = [];

        props.selectionIds.forEach(_id => {
            const url = process.env.REACT_APP_API_URL + "/inventory/items/" + _id;
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            promises.push(axiosInstance.delete(url, config));
        });

        Promise.all(promises)
            .then(res => {
                props.setEditMultiple({ ...props.editMultiple, "open": false });
                props.setSnackbarMessage("Inventory items deleted");
                props.setOpenSnackbar(true);
                props.setUpdating(true);
            })
            .catch(err => {
                setOpenDelete(false);
                setErrorMessage(err.response.data);
                setShowErrorMessage(true);
            });
    };

    useEffect(() => {
        const fetchEntries = () => {
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };
            let promises = [];

            props.selectionIds.forEach(_id => {
                const url = process.env.REACT_APP_API_URL + "/inventory/items/" + _id;

                promises.push(
                    axiosInstance.get(url, config)
                        .then(res => {
                            return { ...res.data, date: dayjs(res.data.date), time: dayjs(res.data.date) };
                        })
                );
            });

            Promise.all(promises).then(results => { setEntries(results) });
        };

        fetchEntries();
    }, [props.editMultiple._id, userContext, setUserContext, props.selectionIds]);

    const setEditMultiple = props.setEditMultiple;

    useEffect(() => {
        const initializeEntry = () => {
            const entrySets = {
                category: new Set(),
                code: new Set(),
                description: new Set(),
                unit: new Set(),
                price: new Set(),
                minimumQuantity: new Set(),
                productNumber: new Set(),
                voc: new Set(),
                grouping: new Set()
            };

            entries.forEach(entry => {
                Object.entries(entrySets).forEach(([key, value]) => {
                    value.add(entry[key]);
                });
            });

            let updatedEntry = {};

            Object.entries(entrySets).forEach(([key, value]) => {
                if (entrySets[key].size > 1) {
                    if (key !== "voc") {
                        updatedEntry[key] = multipleValues;
                    }
                } else {
                    updatedEntry[key] = Array.from(value)[0];
                }
            });

            setEntry(updatedEntry);
        };

        if (!isLoading) {
            if (loaded.current) {
                setEditMultiple(editMultiple => ({ ...editMultiple, modified: true }));
            } else {
                loaded.current = true;
            }
        }

        initializeEntry();
    }, [entries, isLoading, setEditMultiple]);

    useEffect(() => {
        const fieldsNotEmpty = () => {
            return (
                entry.category
                && entry.price
                && entry.code
                && entry.description
                && (!isNaN(entry.price) || entry.price === multipleValues)
                && entry.minimumQuantity !== ""
                && (!isNaN(entry.minimumQuantity) || entry.minimumQuantity === multipleValues)
            );
        };

        if (!isLoading) {
            if (loaded.current) {
                setEditMultiple(editMultiple => ({ ...editMultiple, modified: true }));
            } else {
                loaded.current = true;
            }
        }

        setDisableButton(!fieldsNotEmpty());
    }, [entry, isLoading, setEditMultiple]);

    useEffect(() => setIsLoading(entries.length === 0), [entries]);

    return (!isLoading &&
        <form onSubmit={handleSubmit}>
            <Grid container direction="column" spacing={2.5}>
                <Grid container item>
                    <FieldsOutline title="Details">
                        <DetailsFields values={entry} setValues={setEntry} image={image} setImage={setImage} options={props.options} />
                    </FieldsOutline>
                </Grid>
                <ErrorBox showErrorMessage={showErrorMessage} errorMessage={errorMessage} />
                <Grid container item>
                    <Grid container item xs={6} alignItems="center">
                        <IconButton onClick={() => setOpenDelete(true)}><DeleteOutlineIcon sx={{ fontSize: 30, color: "#495464" }} /></IconButton>
                    </Grid>
                    <Grid container item xs={6} justifyContent="flex-end" alignItems="center">
                        <Grid item sx={{ mr: 2.5 }}>
                            <Button variant="outlined" onClick={() => props.setEditMultiple({ ...props.editMultiple, open: false, modified: false })}>Cancel</Button>
                        </Grid>
                        <Grid item>
                            <Button
                                type="submit"
                                disabled={disableButton}
                                sx={{ backgroundColor: "#8bacaa", color: "#495464", "&:hover": { backgroundColor: "#8bacaa" } }}
                            >
                                Update
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {openDelete && <DeleteEntryDialog open={openDelete} setOpen={setOpenDelete} handleDelete={handleDelete} />}
        </form>
    );
};

export default EditMultipleModalComponent;