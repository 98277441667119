import { useState, useEffect, useRef } from "react";
import { Divider, Grid, IconButton } from "@mui/material";
import Title from "../components/Common/Title";
import InventoryTable from "../components/Inventory/InventoryTable";
import InventoryCards from "../components/Inventory/InventoryCards";
import InventoryDataFilter from "../components/Inventory/InventoryDataFilter";
import ViewStreamIcon from "@mui/icons-material/ViewStream";
import ViewHeadlineIcon from "@mui/icons-material/ViewHeadline";
import ElSnackbar from "../components/Common/ElSnackbar";
import VerticalSplitIcon from "@mui/icons-material/VerticalSplit";
import FlipToFrontIcon from "@mui/icons-material/FlipToFront";
import { useOutletContext } from "react-router-dom";

const Inventory = () => {
    const storageHasDisableModal = window.localStorage.getItem("disableModal") !== null;
    const [rightSidebar, setRightSidebar] = useOutletContext();
    const storageHasCardView = window.localStorage.getItem("cardView") !== null;
    const [data, setData] = useState([]);
    const [cardView, setCardView] = useState(storageHasCardView ? JSON.parse(window.localStorage.getItem("cardView")) : true);
    const width = "100%";
    const topRef = useRef();
    const [rowCountState, setRowCountState] = useState(0);
    const cardViewPageSize = 10;
    const tableViewPageSize = window.localStorage.getItem("inventoryTablePageSize") !== null ? parseInt(window.localStorage.getItem("inventoryTablePageSize")) : 50;
    const [paginationModel, setPaginationModel] = useState({ page: 0, pageSize: cardView ? cardViewPageSize : tableViewPageSize });
    const [loading, setLoading] = useState(false);
    const [updating, setUpdating] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [options, setOptions] = useState({ categories: [], searches: [], locations: [], units: [] });
    const [category, setCategory] = useState();
    const [disableModal, setDisableModal] = useState(storageHasDisableModal ? JSON.parse(window.localStorage.getItem("disableModal")) : false);

    useEffect(() => {
        window.localStorage.setItem("cardView", cardView);
        setLoading(true);
        setPaginationModel({ page: 0, pageSize: cardView ? cardViewPageSize : tableViewPageSize });
    }, [cardView, setPaginationModel, tableViewPageSize]);

    useEffect(() => {
        window.localStorage.setItem("disableModal", disableModal);
    }, [disableModal]);

    return (
        <div ref={topRef}>
            <ElSnackbar open={openSnackbar} setOpen={setOpenSnackbar} message={snackbarMessage} />
            <Title title="Inventory" subtitle="Edit inventory items" />
            <Grid container>
                <Grid container item xs={10}>
                    <InventoryDataFilter
                        setData={setData}
                        data={data}
                        setRowCountState={setRowCountState}
                        paginationModel={paginationModel}
                        setPaginationModel={setPaginationModel}
                        setSnackbarMessage={setSnackbarMessage}
                        setOpenSnackbar={setOpenSnackbar}
                        loading={loading}
                        setLoading={setLoading}
                        setOptions={setOptions}
                        options={options}
                        updating={updating}
                        setUpdating={setUpdating}
                        category={category}
                        setCategory={setCategory}
                    />
                </Grid>
                <Grid container item xs={2} justifyContent="flex-end" alignContent={"flex-end"}>
                    <Grid item>
                        <IconButton onClick={() => setCardView(true)} sx={{ backgroundColor: cardView ? "rgba(0, 0, 0, 0.1)" : "transparent", ":hover": { backgroundColor: cardView ? "rgba(0, 0, 0, 0.1)" : null } }}><ViewStreamIcon /></IconButton>
                        <IconButton onClick={() => setCardView(false)} sx={{ backgroundColor: !cardView ? "rgba(0, 0, 0, 0.1)" : "transparent", ":hover": { backgroundColor: !cardView ? "rgba(0, 0, 0, 0.1)" : null } }}><ViewHeadlineIcon /></IconButton>
                    </Grid>
                    {(!rightSidebar.task || !rightSidebar.task?.open) &&
                        <>
                            <Grid item sx={{ ml: 1.5, mr: 1.5, alignContent: "center" }}>
                                <Divider orientation="vertical" sx={{ height: "90%" }} />
                            </Grid>
                            <Grid item>
                                <IconButton onClick={() => setDisableModal(false)} sx={{ backgroundColor: !disableModal ? "rgba(0, 0, 0, 0.05)" : "transparent", ":hover": { backgroundColor: !disableModal ? "rgba(0, 0, 0, 0.05)" : null } }}><FlipToFrontIcon /></IconButton>
                                <IconButton onClick={() => setDisableModal(true)} sx={{ backgroundColor: disableModal ? "rgba(0, 0, 0, 0.05)" : "transparent", ":hover": { backgroundColor: disableModal ? "rgba(0, 0, 0, 0.05)" : null } }}><VerticalSplitIcon /></IconButton>
                            </Grid>
                        </>
                    }
                </Grid>
            </Grid>
            <br />
            {cardView ?
                <InventoryCards
                    data={data}
                    width={width}
                    paginationModel={paginationModel}
                    setPaginationModel={setPaginationModel}
                    rowCountState={rowCountState}
                    topRef={topRef}
                    setSnackbarMessage={setSnackbarMessage}
                    setOpenSnackbar={setOpenSnackbar}
                    loading={loading}
                    options={options}
                    setUpdating={setUpdating}
                    setCategory={setCategory}
                    setRightSidebar={setRightSidebar}
                    disableModal={disableModal}
                />
                :
                <InventoryTable
                    data={data}
                    rowCountState={rowCountState}
                    paginationModel={paginationModel.pageSize !== cardViewPageSize ? paginationModel : null}
                    setPaginationModel={setPaginationModel}
                    topRef={topRef}
                    setSnackbarMessage={setSnackbarMessage}
                    setOpenSnackbar={setOpenSnackbar}
                    loading={loading}
                    options={options}
                    setUpdating={setUpdating}
                    setCategory={setCategory}
                    setRightSidebar={setRightSidebar}
                    disableModal={disableModal}
                />
            }
        </div>
    );
};

export default Inventory;