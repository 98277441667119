import { createTheme } from "@mui/material/styles";

const theme = createTheme({
    palette: {
        primary: { main: "#495464" },
        secondary: { main: "#bbbfca" },
        error: { main: "#d71313" },
        text: { primary: "#495464", secondary: "#bbbfca" },
        background: { default: "#d9d9d9" }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    backgroundColor: "#495464",
                    color: "#bbbfca",
                    "&:hover": { backgroundColor: "#495464" },
                    "&:disabled": { backgroundColor: "#bbbfca" },
                    paddingLeft: "25px",
                    paddingRight: "25px"
                },
                outlined: {
                    backgroundColor: "transparent",
                    color: "#495464",
                    "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" },
                    paddingLeft: "25px",
                    paddingRight: "25px"
                }
            }
        },
        MuiInputBase: { styleOverrides: { root: { color: "#000000" } } },
        MuiInputLabel: { styleOverrides: { root: { color: "#495464" } } },
        MuiAutocomplete: {
            styleOverrides: {
                paper: { background: "#495464", color: "#bbbfca" },
                option: { "&:hover": { backgroundColor: "rgba(139, 172, 170, 0.6) !important" } }
            }
        },
        MuiPaper: {
            styleOverrides: {
                root: {
                    boxShadow: "0px 2px 4px -1px rgba(0, 0, 0, 0.2), 0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12)"
                }
            }
        },
        MuiInputAdornment: { styleOverrides: { root: { "& .MuiTypography-root": { color: "#495464" } } } },
        MuiDataGrid: {
            styleOverrides: {
                root: {
                    ".MuiDataGrid-overlayWrapperInner": {
                        height: "65vh !important"
                    },
                    ".MuiDataGrid-overlayWrapper": {
                        height: "65vh !important"
                    }
                }
            }
        }
    }
});

export default theme;