import { GridToolbarContainer } from "@mui/x-data-grid";

const DataTableToolbar = props => {
    return (
        <GridToolbarContainer
            sx={{
                "& .css-336lfb-MuiGrid-root": { display: props.showToolbarContainer ? "flex" : "none" }, // Toolbar container
                "@media print": {
                    "& .MuiButtonBase-root": { display: "none" },
                    "& .css-1ledfka-MuiGrid-root": { display: "flex" },
                    "& .MuiTypography-root": { fontFamily: "Arial" }
                }
            }}
        >
            {props.toolbarComponent}
        </GridToolbarContainer>
    );
};

export default DataTableToolbar;