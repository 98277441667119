import { useState, useRef, useEffect } from "react";
import Title from "../components/Common/Title";
import ProjectsTable from "../components/Projects/ProjectsTable";
import ProjectsDataFilter from "../components/Projects/ProjectsDataFilter";
import ElSnackbar from "../components/Common/ElSnackbar";
import { useOutletContext } from "react-router-dom";

const Projects = () => {
    const [data, setData] = useState([]);
    const [options, setOptions] = useState({ projects: [], persons: [], locations: [], codes: [] });
    const topRef = useRef();
    const [rowCountState, setRowCountState] = useState(0);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: window.localStorage.getItem("projectsTablePageSize") !== null ? parseInt(window.localStorage.getItem("projectsTablePageSize")) : 100
    });
    const [loading, setLoading] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [, setRightSidebar] = useOutletContext();

    useEffect(() => {
        setRightSidebar({ open: false });
    }, [setRightSidebar]);

    return (
        <div ref={topRef}>
            <ElSnackbar open={openSnackbar} setOpen={setOpenSnackbar} message={snackbarMessage} />
            <Title title="List of Projects" subtitle="View list of projects" />
            <ProjectsDataFilter
                data={data}
                setData={setData}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                setRowCountState={setRowCountState}
                setSnackbarMessage={setSnackbarMessage}
                setOpenSnackbar={setOpenSnackbar}
                loading={loading}
                setLoading={setLoading}
                options={options}
                setOptions={setOptions}
            />
            <br />
            <ProjectsTable
                data={data}
                rowCountState={rowCountState}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                topRef={topRef}
                setSnackbarMessage={setSnackbarMessage}
                setOpenSnackbar={setOpenSnackbar}
                loading={loading}
                options={options}
                setOptions={setOptions}
            />
        </div>
    );
};

export default Projects;