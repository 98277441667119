import DataTable from "../DataTable/DataTable";
import { Tooltip } from "@mui/material";
import { Grid, Paper, Typography, Link } from "@mui/material";

const VocReportTable = props => {
    const cellParams = { style: { textOverflow: "ellipsis", overflow: "hidden" } };

    const setCategories = props.setCategories;

    const handleClick = (event, field, value) => {
        event.stopPropagation();

        if (field === "category") {
            setCategories([value]);
        }
    };

    const columns = [
        {
            field: "code",
            headerName: "Code",
            minWidth: 70,
            flex: 1.4,
            renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
        },
        {
            field: "quantity",
            headerName: "Qty.",
            type: "number",
            minWidth: 75,
            flex: 0.5,
            renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
        },
        {
            field: "description",
            headerName: "Description",
            minWidth: 310,
            flex: 6,
            renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
        },
        {
            field: "category",
            headerName: "Category",
            minWidth: 125,
            flex: 1.5,
            renderCell: params => <Tooltip title={params.value}>
                <div {...cellParams}>
                    <Link onClick={e => handleClick(e, "category", params.value)} sx={{ cursor: "pointer", textDecoration: "none", "&:hover": { textDecoration: "underline" } }}>
                        {params.value}
                    </Link>
                </div>
            </Tooltip>
        },
        {
            field: "unit",
            headerName: "Unit",
            minWidth: 100,
            flex: 0.5,
            renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
        },
        {
            field: "totalUsed",
            headerName: "Total Used",
            minWidth: 115,
            flex: 1,
            valueGetter: params => params.row.totalUsed + " " + params.row.parsedUnit,
            renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
        }
    ];

    return (
        <Grid container item direction="column" sx={{ width: props.width }}>
            {props.loading ?
                <div style={{ display: "flex", justifyContent: "center", height: "65vh", alignItems: "center" }}>
                    <Typography>Loading data...</Typography>
                </div>
                :
                props.data.length === 0 ?
                    <div style={{ display: "flex", justifyContent: "center", height: "65vh", alignItems: "center" }}>
                        <Typography>No rows</Typography>
                    </div>
                    :
                    props.data.map((group, index) => {
                        return (
                            <Paper
                                key={index}
                                sx={{
                                    backgroundColor: "rgba(255, 255, 255, 0.25)",
                                    marginBottom: "20px",
                                    padding: "25px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    boxShadow: "none"
                                }}
                            >
                                <Grid container item direction="column" spacing={2.5}>
                                    <Grid container item spacing={2.5} justifyContent="space-between">
                                        <Grid item>
                                            <Typography variant="h5">{group.name}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="h6">
                                                {
                                                    group.totals.map((total, index) => {
                                                        const parsedAmount = +parseFloat(total.total).toFixed(2);

                                                        if (index === 0) {
                                                            return parsedAmount + " " + total.unit;
                                                        } else {
                                                            return " + " + parsedAmount + " " + total.unit;
                                                        }
                                                    })
                                                }
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                    <Grid item>
                                        <DataTable
                                            columns={columns}
                                            rows={group.entries}
                                            loading={props.loading}
                                            rowCountState={props.rowCountState}
                                        />
                                    </Grid>
                                </Grid>
                            </Paper>
                        )
                    })
            }
        </Grid>
    );
};

export default VocReportTable;