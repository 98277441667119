import { Button, IconButton, Grid } from "@mui/material";
import { useState, useEffect, useContext, useRef } from "react";
import dayjs from "dayjs";
import FieldsOutline from "../../Common/FieldsOutline";
import DetailsFields from "../Fields/DetailsFields";
import EntryFields from "../Fields/EntryFields";
import DeleteEntryDialog from "../../Common/DeleteEntryDialog";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ErrorBox from "../../Common/ErrorBox";
import NotesFields from "../../Common/NotesFields";
import { combineDateTime } from "../../../utils/utils";
import { UserContext } from "../../../context/UserContext";
import { axiosInstance } from "../../../utils/utils";

const EditMultipleModalComponent = props => {
    const [entry, setEntry] = useState({
        _id: "",
        date: null,
        time: null,
        location: "",
        vendor: "",
        reference: "",
        code: "",
        quantity: "",
        price: "",
        notes: "",
        description: ""
    });

    const [entries, setEntries] = useState([]);
    const [disableButton, setDisableButton] = useState(true);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [userContext, setUserContext] = useContext(UserContext);
    const [openDelete, setOpenDelete] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const loaded = useRef(false);
    const multipleValues = "(Multiple Values)";

    const handleSubmit = event => {
        event.preventDefault();
        setDisableButton(true);

        let updatedEntries = [];

        entries.forEach(fetchedEntry => {
            let updatedEntry = { _id: fetchedEntry._id };

            Object.entries(entry).forEach(([key, value]) => {
                if (key === "date" || key === "time") {
                    if (value === multipleValues) {
                        updatedEntry[key] = fetchedEntry[key];
                    } else {
                        updatedEntry[key] = value;
                    }
                } else {
                    if (value === multipleValues) {
                        updatedEntry[key] = fetchedEntry[key];
                    } else {
                        updatedEntry[key] = value;
                    }
                }
            });

            updatedEntries.push(updatedEntry);
        });

        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };
        let promises = [];

        updatedEntries.forEach(updatedEntry => {
            const date = combineDateTime(updatedEntry.date, updatedEntry.time);

            const url = process.env.REACT_APP_API_URL + "/inventory/stocks/" + updatedEntry._id;
            const body = { ...updatedEntry, date: date };

            body.code = body.code.toLowerCase();

            promises.push(axiosInstance.post(url, body, config));
        });

        Promise.all(promises)
            .then(res => {
                props.setEditMultiple({ ...props.editMultiple, "open": false });
                props.setSnackbarMessage("Stock entries updated");
                props.setOpenSnackbar(true);
            })
            .catch(err => {
                setErrorMessage(err.response.data);
                setShowErrorMessage(true);
                setDisableButton(false);
            });
    };

    const handleDelete = () => {
        let promises = [];

        props.selectionIds.forEach(_id => {
            const url = process.env.REACT_APP_API_URL + "/inventory/stocks/" + _id;
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            promises.push(axiosInstance.delete(url, config));
        });

        Promise.all(promises)
            .then(res => {
                props.setEditMultiple({ ...props.editMultiple, "open": false });
                props.setSnackbarMessage("Stock entries deleted");
                props.setOpenSnackbar(true);
            })
            .catch(err => {
                setOpenDelete(false);
                setErrorMessage(err.response.data);
                setShowErrorMessage(true);
            });
    };

    const handleChange = (index, event) => setEntry(pairs => ({ ...pairs, [event.target.name]: event.target.value }));

    useEffect(() => {
        const fetchEntries = () => {
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };
            const url = process.env.REACT_APP_API_URL + "/inventory/listofstocks";
            const body = { ids: props.selectionIds };

            axiosInstance.post(url, body, config)
                .then(res => {
                    setEntries(res.data.map(entry => ({ ...entry, price: entry.price.toFixed(2), date: dayjs(entry.date), time: dayjs(entry.date) })));
                })
                .catch(err => {
                    // Do nothing
                });
        };

        fetchEntries();
    }, [props.editMultiple._id, userContext, setUserContext, props.selectionIds]);

    const setEditMultiple = props.setEditMultiple;

    useEffect(() => {
        const initializeEntry = () => {
            const entrySets = {
                date: new Set(),
                time: new Set(),
                location: new Set(),
                vendor: new Set(),
                reference: new Set(),
                code: new Set(),
                quantity: new Set(),
                price: new Set(),
                notes: new Set(),
                description: new Set()
            };

            entries.forEach(entry => {
                Object.entries(entrySets).forEach(([key, value]) => {
                    if (key === "date") {
                        entrySets.date.add(new Date(entry.date).toLocaleDateString());
                    }
                    else if (key === "time") {
                        const time = entry.time.get("hour") + ":" + entry.time.get("minute") + ":" + entry.time.get("second") + ":" + entry.time.get("millisecond");
                        entrySets.time.add(time);
                    }
                    else {
                        value.add(entry[key]);
                    }
                });
            });

            let updatedEntry = {};

            Object.entries(entrySets).forEach(([key, value]) => {
                if (entrySets[key].size > 1) {
                    if (key === "date" || key === "time") {
                        updatedEntry[key] = multipleValues;
                    } else {
                        updatedEntry[key] = multipleValues;
                    }
                } else {
                    if (key === "date" || key === "time") {
                        updatedEntry[key] = entries[0]?.date;
                    } else {
                        updatedEntry[key] = Array.from(value)[0];
                    }
                }
            });

            setEntry(updatedEntry);
        };

        if (!isLoading) {
            if (loaded.current) {
                setEditMultiple(editMultiple => ({ ...editMultiple, modified: true }));
            } else {
                loaded.current = true;
            }
        }

        initializeEntry();
    }, [entries, isLoading, setEditMultiple]);

    useEffect(() => {
        const fieldsNotEmpty = () => {
            const requiredFields = ["location", "vendor", "reference", "code", "quantity", "price"];
            return requiredFields.every(field => entry[field]) && (!isNaN(entry.quantity) || entry.quantity === multipleValues) && (entry.price === multipleValues || parseFloat(entry.price) === Math.round(((parseFloat(entry.price) + Number.EPSILON) * 100)) / 100);
        };

        if (!isLoading) {
            if (loaded.current) {
                setEditMultiple(editMultiple => ({ ...editMultiple, modified: true }));
            } else {
                loaded.current = true;
            }
        }

        setDisableButton(!fieldsNotEmpty());
    }, [entry, isLoading, setEditMultiple]);

    useEffect(() => setIsLoading(entries.length === 0), [entries]);

    return (!isLoading &&
        <form onSubmit={handleSubmit}>
            <Grid container direction="column" spacing={2.5}>
                <Grid container item>
                    <FieldsOutline title="Details">
                        <DetailsFields values={entry} setValues={setEntry} options={props.options} />
                    </FieldsOutline>
                </Grid>
                <Grid container item>
                    <FieldsOutline title="Item">
                        <EntryFields code={entry.code} quantity={entry.quantity} price={entry.price} description={entry.description} handleChange={handleChange} options={props.options} inventoryData={props.inventoryData} />
                    </FieldsOutline>
                </Grid>
                <Grid container item>
                    <FieldsOutline title="Notes">
                        <NotesFields values={entry} setValues={setEntry} />
                    </FieldsOutline>
                </Grid>
                <ErrorBox showErrorMessage={showErrorMessage} errorMessage={errorMessage} />
                <Grid container item>
                    <Grid container item xs={6} alignItems="center">
                        <IconButton onClick={() => setOpenDelete(true)}><DeleteOutlineIcon sx={{ fontSize: 30, color: "#495464" }} /></IconButton>
                    </Grid>
                    <Grid container item xs={6} justifyContent="flex-end" alignItems="center">
                        <Grid item sx={{ mr: 2.5 }}>
                            <Button variant="outlined" onClick={() => props.setEditMultiple({ ...props.edit, open: false, modified: false })}>Cancel</Button>
                        </Grid>
                        <Grid item>
                            <Button
                                type="submit"
                                disabled={disableButton}
                                sx={{ backgroundColor: "#8bacaa", color: "#495464", "&:hover": { backgroundColor: "#8bacaa" } }}
                            >
                                Update
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {openDelete && <DeleteEntryDialog open={openDelete} setOpen={setOpenDelete} handleDelete={handleDelete} />}
        </form>
    );
};

export default EditMultipleModalComponent;