import { useState, useContext, useMemo, useCallback } from "react";
import { formatCurrency } from "../../utils/utils";
import DataTable from "../DataTable/DataTable";
import { Tooltip, Link } from "@mui/material";
import { useGridApiRef } from "@mui/x-data-grid";
import InventoryToolbar from "./InventoryToolbar";
import { UserContext } from "../../context/UserContext";
import DataTableFooter from "../DataTable/TableComponents/DataTableFooter";
import TotalEntriesAndCost from "../DataTable/TableComponents/TotalEntriesAndCost";
import DataTableToolbar from "../DataTable/TableComponents/DataTableToolbar";
import TableSummary from "../DataTable/TableComponents/TableSummary";

const InventoryTable = (props) => {
    const [userContext] = useContext(UserContext);
    const [edit, setEdit] = useState({ open: false, title: "Edit item", subtitle: "Modify the selected inventory item", _id: "", modified: false });
    const [selectionIds, setSelectionIds] = useState([]);
    const rows = useMemo(() => props.data, [props.data]);
    const apiRef = useGridApiRef();

    const setCategory = props.setCategory;

    const columns = useMemo(() => {
        const cellParams = { style: { textOverflow: "ellipsis", overflow: "hidden" } };

        const handleClick = (event, field, value) => {
            event.stopPropagation();

            if (field === "category") {
                setCategory(value);
            }
        };

        return [
            {
                field: "code",
                headerName: "Code",
                minWidth: 75,
                flex: 1.5,
                renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
            },
            {
                field: "quantity",
                headerName: "Qty.",
                type: "number",
                minWidth: 65,
                flex: 0.5,
                renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
            },
            {
                field: "productNumber",
                headerName: "Product #",
                minWidth: 115,
                flex: 2,
                renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
            },
            {
                field: "description",
                headerName: "Description",
                minWidth: 325,
                flex: 6,
                renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
            },
            {
                field: "unit",
                headerName: "Unit",
                minWidth: 100,
                flex: 1.5,
                renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
            },
            {
                field: "price",
                headerName: "Price",
                type: "number",
                minWidth: 85,
                flex: 1,
                valueGetter: params => formatCurrency(params.value),
                renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
            },
            {
                field: "latestPrice",
                headerName: "Change",
                type: "number",
                minWidth: 85,
                flex: 1,
                renderCell: params => {
                    let divParams = {};
                    let prefix = "";
                    let content = "";
                    const change = params.row.latestPrice - params.row.price;

                    if (params.row.latestPrice !== undefined) {
                        if (params.row.latestPrice > params.row.price) {
                            divParams = { style: { color: "#d71313", textOverflow: "ellipsis", overflow: "hidden" } };
                            prefix = "+";
                        } else {
                            divParams = { style: { color: "#f99b7d", textOverflow: "ellipsis", overflow: "hidden" } };
                        }

                        content = prefix + formatCurrency(change);
                    }

                    return (<Tooltip title={content}><div {...divParams}>{content}</div></Tooltip>);
                }
            },
            {
                field: "category",
                headerName: "Category",
                minWidth: 115,
                flex: 2,
                renderCell: params => <Tooltip title={params.value}>
                    <div {...cellParams}>
                        <Link onClick={e => handleClick(e, "category", params.value)} sx={{ cursor: "pointer", textDecoration: "none", "&:hover": { textDecoration: "underline" } }}>
                            {params.value}
                        </Link>
                    </div>
                </Tooltip>
            },
            {
                field: "grouping",
                headerName: "Grouping",
                minWidth: 115,
                flex: 2,
                renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
            },
            {
                field: "voc",
                headerName: "VOC",
                minWidth: 65,
                flex: 0.5,
                renderCell: params => <div {...cellParams}>{params.value ? "VOC" : ""}</div>
            }
        ];
    }, [setCategory]);

    const CustomFooter = useCallback(() => { return (<DataTableFooter rows={rows} />) }, [rows]);

    const CustomToolbar = useCallback(() => {
        const totalComponent = <TotalEntriesAndCost totalEntries={props.rowCountState} />;
        const toolbarComponent = <TableSummary totalComponent={totalComponent} title="List of Inventory Items" />;

        return (<DataTableToolbar toolbarComponent={toolbarComponent} />)
    }, [props.rowCountState]);

    const handlePageChange = useCallback(newPaginationModel => {
        props.setPaginationModel(newPaginationModel);
        window.localStorage.setItem("inventoryTablePageSize", newPaginationModel.pageSize);

        if (props.topRef && props.topRef.current) {
            props.topRef.current.scrollIntoView()
        }
    }, [props]);

    const handleRowClick = useCallback(row => {
        if (userContext._id !== row.user && !userContext.admin) {
            return null;
        }

        setEdit(edit => ({ ...edit, open: true, _id: row._id }));
    }, [userContext._id, userContext.admin]);

    return (
        <div>
            <InventoryToolbar
                apiRef={apiRef}
                selectionIds={selectionIds}
                edit={edit}
                setEdit={setEdit}
                options={props.options}
                setSnackbarMessage={props.setSnackbarMessage}
                setOpenSnackbar={props.setOpenSnackbar}
                setUpdating={props.setUpdating}
                rowCountState={props.rowCountState}
                setRightSidebar={props.setRightSidebar}
                disableModal={props.disableModal}
            />
            <DataTable
                columns={columns}
                rows={rows}
                customFooter={CustomFooter}
                customToolbar={CustomToolbar}
                paginationMode="server"
                paginationModel={props.paginationModel}
                handlePageChange={handlePageChange}
                handleRowClick={handleRowClick}
                rowCountState={props.rowCountState}
                loading={props.loading}
                setSelectionIds={setSelectionIds}
                apiRef={apiRef}
            />
        </div>
    );
};

export default InventoryTable;