import { useState, useContext, useEffect, useMemo, useCallback } from "react";
import ElModal from "../Common/ElModal";
import AddModalComponent from "./ModalComponents/AddModalComponent";
import EditModalComponent from "./ModalComponents/EditModalComponent";
import EditMultipleModalComponent from "./ModalComponents/EditMultipleModalComponent";
import TotalEntriesAndCost from "../DataTable/TableComponents/TotalEntriesAndCost";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";
import { Grid } from "@mui/material";
import DeleteEntryDialog from "../Common/DeleteEntryDialog";
import { UserContext } from "../../context/UserContext";
import { axiosInstance } from "../../utils/utils";

const CheckoutsToolbar = (props) => {
    const [add, setAdd] = useState({ open: false, title: "Add checkout", subtitle: "Record new checkout", modified: false });
    const [editMultiple, setEditMultiple] = useState({ open: false, title: "Edit checkouts", subtitle: "Modify the selected checkout entries", _id: "", modified: false });
    const [openDeleteMultiple, setOpenDeleteMultiple] = useState(false);
    const [userContext, setUserContext] = useContext(UserContext);

    const setRightSidebar = props.setRightSidebar;
    const setEdit = props.setEdit;

    const totalComponent = <TotalEntriesAndCost totalEntries={props.rowCountState} totalCost={props.totalCost} />;

    const addModalComponent = useMemo(() => <AddModalComponent
        add={add}
        setAdd={setAdd}
        setSnackbarMessage={props.setSnackbarMessage}
        setOpenSnackbar={props.setOpenSnackbar}
        inventoryData={props.inventoryData}
        options={props.options}
    />, [add, props.inventoryData, props.options, props.setSnackbarMessage, props.setOpenSnackbar]);

    const editModalComponent = useMemo(() => <EditModalComponent
        edit={props.edit}
        setEdit={setEdit}
        setSnackbarMessage={props.setSnackbarMessage}
        setOpenSnackbar={props.setOpenSnackbar}
        inventoryData={props.inventoryData}
        options={props.options}
    />, [props.edit, setEdit, props.inventoryData, props.options, props.setSnackbarMessage, props.setOpenSnackbar]);

    const editMultipleModalComponent = useMemo(() => <EditMultipleModalComponent
        editMultiple={editMultiple}
        setEditMultiple={setEditMultiple}
        setSnackbarMessage={props.setSnackbarMessage}
        setOpenSnackbar={props.setOpenSnackbar}
        inventoryData={props.inventoryData}
        options={props.options}
        selectionIds={props.selectionIds}
    />, [editMultiple, props.selectionIds, props.inventoryData, props.options, props.setSnackbarMessage, props.setOpenSnackbar]);

    const handleDelete = () => {
        let promises = [];

        props.selectionIds.forEach(_id => {
            const url = process.env.REACT_APP_API_URL + "/inventory/checkouts/" + _id;
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            promises.push(axiosInstance.delete(url, config));
        });

        Promise.all(promises)
            .then(res => {
                setOpenDeleteMultiple(false);
                props.setSnackbarMessage("Checkout entries deleted")
                props.setOpenSnackbar(true);
            })
            .catch(err => {
                setOpenDeleteMultiple(false);
                props.setSnackbarMessage("Unable to delete checkout entries")
                props.setOpenSnackbar(true);
            });
    };

    useEffect(() => {
        if (props.disableModal) {
            setRightSidebar(rightSidebar => ({ ...rightSidebar, component: addModalComponent, task: add, setTask: setAdd }));
        }
    }, [setRightSidebar, add, addModalComponent, props.disableModal]);

    useEffect(() => {
        if (props.disableModal) {
            setRightSidebar(rightSidebar => ({ ...rightSidebar, component: editModalComponent, task: props.edit, setTask: setEdit }));
        }
    }, [setRightSidebar, props.edit, editModalComponent, props.disableModal, setEdit]);

    useEffect(() => {
        if (props.disableModal) {
            setRightSidebar(rightSidebar => ({ ...rightSidebar, component: editMultipleModalComponent, task: editMultiple, setTask: setEditMultiple }));
        }
    }, [setRightSidebar, editMultiple, editMultipleModalComponent, props.disableModal]);

    const handleKeyPress = useCallback(event => {
        if (event.altKey === true && event.keyCode === 187 && !add.open && !props.edit.open && !editMultiple.open) {
            setAdd({ ...add, open: true });
        }
    }, [add, props.edit, editMultiple]);

    useEffect(() => {
        document.addEventListener("keydown", handleKeyPress);

        return () => {
            document.removeEventListener("keydown", handleKeyPress);
        };
    }, [handleKeyPress]);

    return (
        <Grid container wrap="nowrap">
            <Grid container item spacing={2} alignItems="center" justifyContent="flex-start">
                <Grid item>
                    <Button onClick={() => props.apiRef.current.exportDataAsPrint()} size="medium">
                        <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><PrintIcon sx={{ fontSize: 20 }} /></span>
                        Print
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        onClick={() => setAdd({ ...add, open: true })}
                        size="medium"
                        sx={{ backgroundColor: "#8bacaa", color: "#495464", "&:hover": { backgroundColor: "#8bacaa" } }}
                    >
                        <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><AddIcon sx={{ fontSize: 20 }} /></span>
                        Add
                    </Button>
                </Grid>
                {props.selectionIds.length > 0 &&
                    <Grid item>
                        <Button
                            onClick={() => setEditMultiple({ ...editMultiple, open: true })}
                            size="medium"
                            sx={{ backgroundColor: "#5e6673", color: "#bbbfca", "&:hover": { backgroundColor: "#5e6673" } }}
                        >
                            <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><EditIcon sx={{ fontSize: 20 }} /></span>
                            Edit
                        </Button>
                    </Grid>
                }
                {props.selectionIds.length > 0 &&
                    <Grid item>
                        <Button
                            onClick={() => setOpenDeleteMultiple(true)}
                            size="medium"
                            sx={{ backgroundColor: "#BB3030", color: "#d9d9d9", "&:hover": { backgroundColor: "#BB3030" } }}
                        >
                            <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><EditIcon sx={{ fontSize: 20 }} /></span>
                            Delete
                        </Button>
                    </Grid>
                }
            </Grid>
            <Grid container item>{totalComponent}</Grid>
            {add.open && !props.disableModal && <ElModal task={add} setTask={setAdd} modalComponent={addModalComponent} />}
            {props.edit.open && !props.disableModal && <ElModal task={props.edit} setTask={props.setEdit} modalComponent={editModalComponent} />}
            {editMultiple.open && !props.disableModal && <ElModal task={editMultiple} setTask={setEditMultiple} modalComponent={editMultipleModalComponent} />}
            {openDeleteMultiple && <DeleteEntryDialog open={openDeleteMultiple} setOpen={setOpenDeleteMultiple} handleDelete={handleDelete} />}
        </Grid>
    );
};

export default CheckoutsToolbar;