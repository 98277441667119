import { useState, useEffect, useContext, useRef } from "react";
import DataFilter from "../DataTable/DataFilter.js";
import dayjs from "dayjs";
import { UserContext } from "../../context/UserContext";
import { axiosInstance } from "../../utils/utils.js";

const VocReportDataFilter = props => {
    const [fields, setFields] = useState([]);
    const [dates, setDates] = useState({ startDate: dayjs(new Date(new Date().getFullYear(), new Date().getMonth() - 1, 1)), endDate: dayjs(new Date().setDate(0)) });
    const [selectedDates, setSelectedDates] = useState([]);
    const [userContext, setUserContext] = useContext(UserContext);
    const [location, setLocation] = useState(userContext.defaultLocation ? userContext.defaultLocation : "");
    const [categoryOptions, setCategoryOptions] = useState([]);
    const [locationOptions, setLocationOptions] = useState([]);
    const [listening, setListening] = useState(false);
    const initialized = useRef(false);

    const setData = props.setData;
    const setRowCountState = props.setRowCountState;
    const setLoading = props.setLoading;
    const setSnackbarMessage = props.setSnackbarMessage;
    const setOpenSnackbar = props.setOpenSnackbar;
    const setTitle = props.setTitle;
    const setCategories = props.setCategories;

    useEffect(() => {
        if (initialized.current) {
            setData([]);
            setLoading(true);
        }
    }, [setData, setLoading, props.categories]);

    useEffect(() => {
        if (initialized.current) {
            setSelectedDates([]);
        }
    }, [dates]);

    useEffect(() => {
        if (initialized.current) {
            setCategories([]);
        }
    }, [location, selectedDates, setCategories]);

    useEffect(() => {
        if (!listening) {
            const eventSource = new EventSource(process.env.REACT_APP_API_URL + "/inventory/checkouts/updates");

            eventSource.onmessage = event => {
                setUserContext(userContext => ({ ...userContext, refresh: !userContext.refresh }));
            };

            return () => eventSource.close();
        }

        setListening(true);
    }, [listening, setUserContext]);

    useEffect(() => {
        const fetchData = () => {
            let url = process.env.REACT_APP_API_URL + "/inventory/checkouts";
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            let query = "";

            const startDate = new Date(dates.startDate.set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0)).toISOString().slice(0, 10);
            const endDate = new Date(dates.endDate.set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0)).toISOString().slice(0, 10);

            query += "&start_date=" + startDate + "&end_date=" + endDate;
            query += "&voc=true";

            let title = "";

            if (location) {
                query += "&location=" + encodeURIComponent(location);
                title = location;
            }

            if (props.categories.length > 0) {
                query += "&categories=[";

                props.categories.forEach(category => {
                    query += '"' + encodeURIComponent(category) + '",';
                });

                query = query.slice(0, -1);
                query += "]";
            }

            if (selectedDates.length > 0) {
                query += "&selected_dates=[";

                selectedDates.forEach(date => {
                    query += '"' + date + '",';
                });

                query = query.slice(0, -1);
                query += "]";
            }

            if (query !== "") {
                url += "?" + query.slice(1);
            }

            if (title !== "") {
                title = title.slice(3) + " - VOC Report";
            } else {
                title = "VOC Report";
            }

            setTitle(title);

            axiosInstance.get(url, config)
                .then(res => {
                    setData(res.data.entries);
                    setRowCountState(res.data.totalEntries);
                    setLocationOptions(res.data.locationOptions);

                    if (props.categories.length === 0) {
                        setCategoryOptions(res.data.categoryOptions);
                    }

                    setLoading(false);
                    initialized.current = true;
                })
                .catch(err => {
                    setLoading(false);
                    setSnackbarMessage("Unable to load report data");
                    setOpenSnackbar(true);
                });
        };

        if (props.loading) {
            fetchData();
        }
    }, [props.loading, dates, userContext, setUserContext, props.paginationModel, location, setData, setRowCountState, setLoading, setSnackbarMessage, setOpenSnackbar, setTitle, selectedDates, props.categories]);

    useEffect(() => {
        const updateFields = () => {
            setFields([
                { name: "location", label: "Location", options: locationOptions, state: location, setState: setLocation },
                { name: "category", label: "Category", options: categoryOptions, state: props.categories, setState: setCategories, multiple: true }
            ]);
        };

        updateFields();
    }, [location, props.categories, categoryOptions, locationOptions, setCategories]);

    return (
        <DataFilter
            fields={fields}
            setData={props.setData}
            dates={dates}
            setDates={setDates}
            selectedDates={selectedDates}
            setSelectedDates={setSelectedDates}
            data={props.data}
            setTitle={props.setTitle}
            enableDateSelection={true}
        />
    );
};

export default VocReportDataFilter;