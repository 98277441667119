import { useState, useEffect, useRef, useContext, useMemo } from "react";
import Title from "../components/Common/Title";
import CreateReportTable from "../components/CreateReport/CreateReportTable";
import CreateReportDataFilter from "../components/CreateReport/CreateReportDataFilter";
import ColumnSelector from "../components/DataTable/ColumnSelector";
import PriceAdjuster from "../components/DataTable/PriceAdjuster";
import { Grid, Divider, Link } from "@mui/material";
import ElSnackbar from "../components/Common/ElSnackbar";
import { UserContext } from "../context/UserContext";
import { useNavigate, useOutletContext } from "react-router-dom";

const CreateReport = () => {
    const [userContext] = useContext(UserContext);
    const [fetchedData, setFetchedData] = useState([]);
    const [title, setTitle] = useState("");
    const columns = useMemo(() => ["code", "quantity", "date", "project", "work", "person", "location", "description", "price", "extended"], []);
    const [selectedColumns, setSelectedColumns] = useState({ ...columns.reduce((columns, column) => ({ ...columns, [column]: true }), {}), work: false, location: false });
    const [adjustment, setAdjustment] = useState(userContext.defaultAdjustment ? userContext.defaultAdjustment : "");
    const topRef = useRef();
    const [rowCountState, setRowCountState] = useState(0);
    const [fetchedRowCountState, setFetchedRowCountState] = useState(0);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: window.localStorage.getItem("createReportTablePageSize") !== null ? parseInt(window.localStorage.getItem("createReportTablePageSize")) : 250
    });
    const [totalCost, setTotalCost] = useState(0);
    const [fetchedTotalCost, setFetchedTotalCost] = useState(0);
    const [adjustedTotalCost, setAdjustedTotalCost] = useState(0);
    const [loading, setLoading] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [project, setProject] = useState();
    const [work, setWork] = useState();
    const [persons, setPersons] = useState([]);
    const [codes, setCodes] = useState([]);
    const [, setRightSidebar] = useOutletContext();
    const navigate = useNavigate();

    useEffect(() => {
        setRightSidebar({ open: false });
    }, [setRightSidebar]);

    useEffect(() => {
        if (!isNaN(adjustment) && adjustment >= 0) {
            setAdjustedTotalCost(totalCost * (1 + (0.01 * adjustment)));
        }
    }, [adjustment, totalCost]);

    useEffect(() => {
        setTotalCost(fetchedTotalCost);
    }, [fetchedTotalCost]);

    useEffect(() => {
        setRowCountState(fetchedRowCountState);
    }, [fetchedRowCountState]);

    return (
        <div ref={topRef}>
            <ElSnackbar open={openSnackbar} setOpen={setOpenSnackbar} message={snackbarMessage} />
            <Title title="Create Report" subtitle="Generate project reports" />
            <CreateReportDataFilter
                setTitle={setTitle}
                data={fetchedData}
                setData={setFetchedData}
                setTotalCost={setFetchedTotalCost}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                setRowCountState={setFetchedRowCountState}
                setSnackbarMessage={setSnackbarMessage}
                setOpenSnackbar={setOpenSnackbar}
                loading={loading}
                setLoading={setLoading}
                project={project}
                setProject={setProject}
                work={work}
                setWork={setWork}
                persons={persons}
                setPersons={setPersons}
                codes={codes}
                setCodes={setCodes}
            />
            <br />
            <Grid container spacing={2.5} alignItems="center" sx={{ marginBottom: "15px" }}>
                <Grid item><ColumnSelector columns={columns} selectedColumns={selectedColumns} setSelectedColumns={setSelectedColumns} /></Grid>
                <Grid item sx={{ marginLeft: "10px", marginRight: "15px" }}><Divider orientation="vertical" sx={{ height: "45px" }} /></Grid>
                <Grid item><PriceAdjuster adjustment={adjustment} setAdjustment={setAdjustment} /></Grid>
                <Grid item sx={{ marginLeft: "10px", marginRight: "15px" }}><Divider orientation="vertical" sx={{ height: "45px" }} /></Grid>
                <Grid item>
                    <Link onClick={() => navigate("/projects")} sx={{ cursor: "pointer", textDecoration: "underline", "&:hover": { textDecoration: "underline" } }}>
                        List of Projects
                    </Link>
                </Grid>
            </Grid>
            <CreateReportTable
                data={fetchedData}
                title={title}
                selectedColumns={selectedColumns}
                rowCountState={rowCountState}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                totalCost={totalCost}
                adjustedTotalCost={adjustedTotalCost}
                fetchedTotalCost={fetchedTotalCost}
                fetchedRowCountState={fetchedRowCountState}
                setTotalCost={setTotalCost}
                setRowCountState={setRowCountState}
                topRef={topRef}
                setSnackbarMessage={setSnackbarMessage}
                setOpenSnackbar={setOpenSnackbar}
                loading={loading}
                setLoading={setLoading}
                adjustment={adjustment}
                setSelectedColumns={selectedColumns}
                setProject={setProject}
                setWork={setWork}
                setPersons={setPersons}
                setCodes={setCodes}
            />
        </div>
    );
};

export default CreateReport;