import { Grid, TextField } from "@mui/material";

const NotesFields = props => {
    const handleChange = (name, value) => props.setValues(pairs => ({ ...pairs, [name]: value }));

    return (
        <Grid container item>
            <TextField
                type="text"
                sx={{ width: "72%" }}
                variant="outlined"
                onChange={event => handleChange(event.target.name, event.target.value)}
                name="notes"
                label="Notes"
                value={String(props.values.notes)}
                multiline
                rows={6}
            />
        </Grid>
    );
};

export default NotesFields;