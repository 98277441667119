import { Button, IconButton } from "@mui/material";
import { Grid } from "@mui/material";
import { useState, useEffect, useContext, useRef } from "react";
import FieldsOutline from "../../Common/FieldsOutline";
import DetailsFields from "../Fields/DetailsFields";
import DeleteEntryDialog from "../../Common/DeleteEntryDialog";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ErrorBox from "../../Common/ErrorBox";
import { UserContext } from "../../../context/UserContext";
import { Buffer } from "buffer";
import { axiosInstance } from "../../../utils/utils";

const EditModalComponent = props => {
    const [entry, setEntry] = useState(
        {
            _id: "",
            category: "",
            code: "",
            description: "",
            unit: "",
            price: "",
            minimumQuantity: "",
            productNumber: "",
            voc: false,
            grouping: ""
        }
    );

    const [image, setImage] = useState();
    const [disableButton, setDisableButton] = useState(true);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [userContext, setUserContext] = useContext(UserContext);
    const [openDelete, setOpenDelete] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const loaded = useRef(false);

    const handleSubmit = event => {
        event.preventDefault();
        setDisableButton(true);

        const url = process.env.REACT_APP_API_URL + "/inventory/items/" + props.edit._id;
        const payload = entry;
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

        axiosInstance.postForm(url, payload, config)
            .then(res => {
                props.setEdit({ ...props.edit, "open": false });
                props.setSnackbarMessage("Inventory item updated");
                props.setOpenSnackbar(true);
                props.setUpdating(true);
            })
            .catch(err => {
                setErrorMessage(err.response.data);
                setShowErrorMessage(true);
                setDisableButton(false);
            });
    };

    const handleDelete = () => {
        const url = process.env.REACT_APP_API_URL + "/inventory/items/" + props.edit._id;
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

        axiosInstance.delete(url, config)
            .then(res => {
                props.setEdit({ ...props.edit, "open": false });
                props.setSnackbarMessage("Inventory item deleted");
                props.setOpenSnackbar(true);
                props.setUpdating(true);
            })
            .catch(err => {
                setOpenDelete(false);
                setErrorMessage(err.response.data);
                setShowErrorMessage(true);
            });
    };

    useEffect(() => {
        const fetchEntry = () => {
            let url = process.env.REACT_APP_API_URL + "/inventory/items/" + props.edit._id;
            let config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            axiosInstance.get(url, config)
                .then(res => {
                    if (res.data.image) {
                        url = process.env.REACT_APP_API_URL + "/inventory/items/images/" + res.data.image;
                        let config = { headers: { Authorization: `Bearer ${userContext.token}` }, responseType: "arraybuffer", userContext: userContext, setUserContext: setUserContext };

                        axiosInstance.get(url, config)
                            .then(res => {
                                const base64Image = Buffer.from(res.data, "binary").toString("base64");
                                setImage(`data:image/*;base64,${base64Image}`);
                            })
                            .catch(err => { });
                    }

                    delete res.data.image;
                    setEntry(entry => ({ ...entry, ...res.data }));
                })
                .catch(err => {
                    // Do nothing
                });
        };

        fetchEntry();
    }, [props.edit._id, userContext, setUserContext]);

    const setEdit = props.setEdit;

    useEffect(() => {
        const fieldsNotEmpty = () => {
            return (
                entry.category
                && entry.price
                && entry.code
                && entry.description
                && !isNaN(entry.price)
                && entry.minimumQuantity !== ""
                && !isNaN(entry.minimumQuantity)
            );
        };

        if (!isLoading) {
            if (loaded.current) {
                setEdit(edit => ({ ...edit, modified: true }));
            } else {
                loaded.current = true;
            }
        }

        setDisableButton(!fieldsNotEmpty());
    }, [entry, isLoading, setEdit]);

    useEffect(() => setIsLoading(entry._id === ""), [entry]);

    return (!isLoading &&
        <form onSubmit={handleSubmit}>
            <Grid container direction="column" spacing={2.5}>
                <Grid container item>
                    <FieldsOutline title="Details">
                        <DetailsFields values={entry} setValues={setEntry} image={image} setImage={setImage} options={props.options} />
                    </FieldsOutline>
                </Grid>
                <ErrorBox showErrorMessage={showErrorMessage} errorMessage={errorMessage} />
                <Grid container item>
                    <Grid container item xs={6} alignItems="center">
                        <IconButton onClick={() => setOpenDelete(true)}><DeleteOutlineIcon sx={{ fontSize: 30, color: "#495464" }} /></IconButton>
                    </Grid>
                    <Grid container item xs={6} justifyContent="flex-end" alignItems="center">
                        <Grid item sx={{ mr: 2.5 }}>
                            <Button variant="outlined" onClick={() => props.setEdit({ ...props.edit, open: false, modified: false })}>Cancel</Button>
                        </Grid>
                        <Grid item>
                            <Button
                                type="submit"
                                disabled={disableButton}
                                sx={{ backgroundColor: "#8bacaa", color: "#495464", "&:hover": { backgroundColor: "#8bacaa" } }}
                            >
                                Update
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {openDelete && <DeleteEntryDialog open={openDelete} setOpen={setOpenDelete} handleDelete={handleDelete} />}
        </form>
    );
};

export default EditModalComponent;