import { DataGrid } from "@mui/x-data-grid";
import { memo } from "react";

const DataTable = props => {
    return (
        <div style={{ display: "flex" }}>
            <DataGrid
                apiRef={props.apiRef}
                rows={props.rows}
                columns={props.columns}
                columnVisibilityModel={props.selectedColumns}
                slots={{ toolbar: props.customToolbar ? props.customToolbar : "div", footer: props.customFooter ? props.customFooter : "div" }}
                checkboxSelection={props.setSelectionIds !== undefined}
                onRowSelectionModelChange={ids => { props.setSelectionIds(ids) }}
                getRowHeight={() => "auto"}
                getRowId={row => row._id}
                disableRowSelectionOnClick
                disableColumnMenu
                onRowClick={({ row }) => props.handleRowClick ? props.handleRowClick(row) : null}
                initialState={{ pagination: { paginationModel: { pageSize: 50 } } }}
                pageSizeOptions={[50, 100, 250, 1000]}
                paginationMode={props.paginationMode === "server" ? "server" : "client"}
                paginationModel={props.paginationModel}
                onPaginationModelChange={props.handlePageChange}
                rowCount={props.rowCountState}
                loading={props.loading}
                sx={props.sx ?
                    props.sx
                    :
                    {
                        fontSize: 16,
                        border: 0,
                        "& .MuiDataGrid-cell": {
                            paddingTop: "12px",
                            paddingBottom: "12px",
                            display: "flex",
                            alignItems: "flex-start",
                            border: 0,
                            "&:focus-within, &:focus": { outline: "none" }
                        },
                        "& .MuiDataGrid-columnHeaders": { borderColor: "#bbbfca" },
                        "& .MuiDataGrid-iconSeparator": { color: "#bbbfca" },
                        "& .MuiDataGrid-footerContainer": { border: 0 },
                        "&.MuiDataGrid-root": { lineHeight: "normal" }, // Stabilize table height
                        "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": { display: "none" },
                        "& .MuiCheckbox-root": { padding: 0 },
                        "@media print": {
                            color: "#000000",
                            display: "table", // Fit columns onto page and avoid break inside rows
                            ".MuiDataGrid-footerContainer": { display: "none" },
                            ".MuiDataGrid-columnHeaderCheckbox": { display: "none" },
                            ".css-i4bv87-MuiSvgIcon-root": { color: "white" },
                            "@page": { size: "landscape" }
                        }
                    }
                }
            />
        </div>
    );
};

export default memo(DataTable);