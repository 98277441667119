import { Grid, TextField, Autocomplete, createFilterOptions } from "@mui/material";
import { LocalizationProvider, DatePicker, TimePicker } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";

const DetailsFields = props => {
    const filterOptions = createFilterOptions({ matchFrom: "start" });
    const gridItemParams = { sx: { width: "50%" } };
    const autocompleteParams = { fullWidth: true, freeSolo: true, autoSelect: true, autoComplete: true, autoHighlight: true, filterOptions: filterOptions };
    const textFieldParams = { type: "text", fullWidth: true, variant: "outlined", onChange: event => handleChange(event.target.name, event.target.value) };
    const dateTimeParams = { sx: { width: "100%", ".Mui-error": { color: "#495464" }, ".Mui-error .MuiOutlinedInput-notchedOutline": { borderColor: "rgba(0, 0, 0, 0.2)" } } };
    const multipleValues = "(Multiple Values)";

    const handleChange = (name, value) => props.setValues(pairs => ({ ...pairs, [name]: value }));

    const handleDateChange = (name, value) => {
        if (!Date.parse(value)) {
            handleChange(name, "");
        } else {
            handleChange(name, value);
        }
    };

    const formatDateDisplay = (name, format) => {
        if (props.values[name] === multipleValues) {
            return multipleValues;
        } else if (props.values[name]) {
            return dayjs(props.values[name]).format(format);
        } else {
            return "";
        }
    };

    return (
        <>
            <Grid container item spacing={2.5}>
                <Grid item {...gridItemParams}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <DatePicker
                            {...dateTimeParams}
                            label="Date"
                            value={props.values.date}
                            onChange={value => handleDateChange("date", value)}
                            slotProps={{ textField: { InputProps: { value: formatDateDisplay("date", "MM/DD/YYYY") } } }}
                        />
                    </LocalizationProvider>
                </Grid>
                <Grid item {...gridItemParams}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <TimePicker
                            {...dateTimeParams}
                            label="Time"
                            value={props.values.time}
                            onChange={value => handleDateChange("time", value)}
                            slotProps={{ textField: { InputProps: { value: formatDateDisplay("time", "hh:mm a") } } }}
                        />
                    </LocalizationProvider>
                </Grid>
            </Grid>
            <Grid container item {...gridItemParams}>
                <Autocomplete
                    {...autocompleteParams}
                    options={props.options.locations}
                    renderInput={params => (<TextField {...params} label="Location" autoFocus={props.values.location === ""} />)}
                    onInputChange={(event, value) => handleChange("location", value)}
                    inputValue={String(props.values.location)}
                />
            </Grid>
            <Grid container item {...gridItemParams}>
                <Autocomplete
                    {...autocompleteParams}
                    options={props.options.vendors}
                    renderInput={params => (<TextField {...params} label="Vendor" autoFocus={props.values.location !== "" && props.values.vendor === ""} />)}
                    onInputChange={(event, value) => handleChange("vendor", value)}
                    inputValue={String(props.values.vendor)}
                />
            </Grid>
            <Grid container item {...gridItemParams}>
                <TextField {...textFieldParams} name="reference" label="Reference" value={String(props.values.reference)} />
            </Grid>
        </>
    );
};

export default DetailsFields;