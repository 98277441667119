import { Typography } from "@mui/material";

const Title = props => {
    return (
        <div>
            <Typography variant="h4" sx={{ marginBottom: "5px" }}>{props.title}</Typography>
            <Typography variant="h6" sx={{ marginBottom: "18px" }}>{props.subtitle}</Typography>
        </div>
    );
};

export default Title;