import { useState, useContext, useMemo, useCallback, useEffect } from "react";
import dayjs from "dayjs";
import DataTable from "../DataTable/DataTable";
import { formatCurrency } from "../../utils/utils";
import DataTableFooter from "../DataTable/TableComponents/DataTableFooter";
import DataTableToolbar from "../DataTable/TableComponents/DataTableToolbar";
import TotalEntriesAndCost from "../DataTable/TableComponents/TotalEntriesAndCost";
import { Tooltip, Link } from "@mui/material";
import { UserContext } from "../../context/UserContext";
import { useGridApiRef } from "@mui/x-data-grid";
import CheckoutsToolbar from "./CheckoutsToolbar";
import TableSummary from "../DataTable/TableComponents/TableSummary";
import { axiosInstance } from "../../utils/utils";

const CheckoutsTable = props => {
    const [edit, setEdit] = useState({ open: false, title: "Edit checkout", subtitle: "Modify the selected checkout entry", _id: "", modified: false });
    const [userContext, setUserContext] = useContext(UserContext);
    const [selectionIds, setSelectionIds] = useState([]);
    const [inventoryData, setInventoryData] = useState([]);
    const rows = useMemo(() => props.data, [props.data]);
    const apiRef = useGridApiRef();

    const setOptions = props.setOptions;
    const setProject = props.setProject;
    const setLocation = props.setLocation;
    const setPersons = props.setPersons;
    const setCodes = props.setCodes;

    const columns = useMemo(() => {
        const cellParams = { style: { textOverflow: "ellipsis", overflow: "hidden" } };

        const handleClick = (event, field, value) => {
            event.stopPropagation();

            if (field === "project") {
                setProject(value);
            } else if (field === "location") {
                setLocation(value);
            } else if (field === "person") {
                setPersons([value]);
            } else if (field === "code") {
                setCodes([value]);
            }
        };

        return [
            {
                field: "code",
                headerName: "Code",
                minWidth: 70,
                flex: 1.4,
                renderCell: params => <Tooltip title={params.value}>
                    <div {...cellParams}>
                        <Link onClick={e => handleClick(e, "code", params.value)} sx={{ cursor: "pointer", textDecoration: "none", "&:hover": { textDecoration: "underline" } }}>
                            {params.value}
                        </Link>
                    </div>
                </Tooltip>
            },
            {
                field: "quantity",
                headerName: "Qty.",
                type: "number",
                minWidth: 60,
                flex: 0.5,
                renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
            },
            {
                field: "date",
                headerName: "Date",
                minWidth: 110,
                flex: 0.5,
                valueGetter: params => dayjs(params.value).format("MM-DD HH:mm"),
                renderCell: params => <Tooltip title={dayjs(params.row.date).format("M/D/YYYY hh:mm a")}><div {...cellParams}>{params.value}</div></Tooltip>
            },
            {
                field: "project",
                headerName: "Project",
                minWidth: 105,
                flex: 2,
                renderCell: params => <Tooltip title={params.value}>
                    <div {...cellParams}>
                        <Link onClick={e => handleClick(e, "project", params.value)} sx={{ cursor: "pointer", textDecoration: "none", "&:hover": { textDecoration: "underline" } }}>
                            {params.value}
                        </Link>
                    </div>
                </Tooltip>
            },
            {
                field: "work",
                headerName: "Work",
                minWidth: 105,
                flex: 2,
                renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
            },
            {
                field: "person",
                headerName: "Person",
                minWidth: 105,
                flex: 2,
                renderCell: params => <Tooltip title={params.value}>
                    <div {...cellParams}>
                        <Link onClick={e => handleClick(e, "person", params.value)} sx={{ cursor: "pointer", textDecoration: "none", "&:hover": { textDecoration: "underline" } }}>
                            {params.value}
                        </Link>
                    </div>
                </Tooltip>
            },
            {
                field: "location",
                headerName: "Location",
                minWidth: 85,
                flex: 2,
                renderCell: params => <Tooltip title={params.value}>
                    <div {...cellParams}>
                        <Link onClick={e => handleClick(e, "location", params.value)} sx={{ cursor: "pointer", textDecoration: "none", "&:hover": { textDecoration: "underline" } }}>
                            {params.value}
                        </Link>
                    </div>
                </Tooltip>
            },
            {
                field: "description",
                headerName: "Description",
                minWidth: 310,
                flex: 6,
                renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
            },
            {
                field: "price",
                headerName: "Price",
                type: "number",
                minWidth: 95,
                flex: 1,
                valueGetter: params => formatCurrency(params.value),
                renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
            },
            {
                field: "extended",
                headerName: "Extended",
                type: "number",
                minWidth: 105,
                flex: 1,
                valueGetter: params => formatCurrency(params.row.price * params.row.quantity),
                renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
            },
            {
                field: "notes",
                headerName: "Notes",
                minWidth: 180,
                flex: 2.5,
                renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
            }
        ];
    }, [setLocation, setProject, setCodes, setPersons]);

    const CustomFooter = useCallback(() => { return (<DataTableFooter rows={rows} />) }, [rows]);

    const CustomToolbar = useCallback(() => {
        const totalComponent = <TotalEntriesAndCost totalEntries={props.rowCountState} totalCost={props.totalCost} />;
        const toolbarComponent = <TableSummary totalComponent={totalComponent} title="List of Checkouts" columns={columns} />;

        return (<DataTableToolbar toolbarComponent={toolbarComponent} />)
    }, [props.rowCountState, props.totalCost, columns]);

    const handlePageChange = useCallback(newPaginationModel => {
        props.setPaginationModel(newPaginationModel);
        window.localStorage.setItem("checkoutsTablePageSize", newPaginationModel.pageSize);

        if (props.topRef && props.topRef.current) {
            props.topRef.current.scrollIntoView()
        }
    }, [props]);

    const handleRowClick = useCallback(row => {
        if (userContext._id !== row.user && !userContext.admin) {
            return null;
        }

        setEdit(edit => ({ ...edit, open: true, _id: row._id }));
    }, [userContext._id, userContext.admin]);

    useEffect(() => {
        const fetchInventoryData = () => {
            let url = process.env.REACT_APP_API_URL + "/inventory/items?sort=code:asc";
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            axiosInstance.get(url, config)
                .then(res => {
                    setInventoryData(res.data.entries);
                    const codes = res.data.entries.map(item => item.code);
                    const descriptions = res.data.entries.map(item => item.description);
                    setOptions(options => ({ ...options, allCodes: codes, allDescriptions: descriptions }));
                })
                .catch(err => { });
        };

        fetchInventoryData();
    }, [userContext, setUserContext, setOptions]);

    return (
        <div>
            <CheckoutsToolbar
                apiRef={apiRef}
                selectionIds={selectionIds}
                edit={edit}
                setEdit={setEdit}
                options={props.options}
                setSnackbarMessage={props.setSnackbarMessage}
                setOpenSnackbar={props.setOpenSnackbar}
                rowCountState={props.rowCountState}
                totalCost={props.totalCost}
                inventoryData={inventoryData}
                setRightSidebar={props.setRightSidebar}
                disableModal={props.disableModal}
            />
            <DataTable
                columns={columns}
                rows={rows}
                customFooter={CustomFooter}
                customToolbar={CustomToolbar}
                paginationMode="server"
                paginationModel={props.paginationModel}
                handlePageChange={handlePageChange}
                handleRowClick={handleRowClick}
                rowCountState={props.rowCountState}
                loading={props.loading}
                setSelectionIds={setSelectionIds}
                apiRef={apiRef}
            />
        </div>
    );
};

export default CheckoutsTable;