import { useState, useEffect, useContext, useRef } from "react";
import DataFilter from "../DataTable/DataFilter.js";
import { UserContext } from "../../context/UserContext";
import { axiosInstance } from "../../utils/utils.js";

const InventoryDataFilter = props => {
    const [userContext, setUserContext] = useContext(UserContext);
    const [location, setLocation] = useState(userContext.defaultLocation ? userContext.defaultLocation : "");
    const [search, setSearch] = useState();
    const initialized = useRef(false);

    const setData = props.setData;
    const setRowCountState = props.setRowCountState;
    const setLoading = props.setLoading;
    const setUpdating = props.setUpdating;
    const setSnackbarMessage = props.setSnackbarMessage;
    const setOpenSnackbar = props.setOpenSnackbar;
    const setPaginationModel = props.setPaginationModel;
    const setOptions = props.setOptions;
    const setCategory = props.setCategory;

    const fields = [{ name: "category", label: "Category", options: props.options.categories, state: props.category, setState: setCategory }];

    useEffect(() => {
        if (initialized.current) {
            setData([]);
            setLoading(true);
        }
    }, [setData, setLoading, props.paginationModel,]);

    useEffect(() => {
        if (initialized.current) {
            setData([]);
            setPaginationModel(paginationModel => ({ ...paginationModel, page: 0 }));
        }
    }, [location, props.category, search, setPaginationModel, setData]);

    useEffect(() => {
        const fetchData = () => {
            let url = process.env.REACT_APP_API_URL + "/inventory/items?page=" + props.paginationModel.page + "&limit=" + props.paginationModel.pageSize;;
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            if (location) {
                url += "&location=" + encodeURIComponent(location);
            }

            if (search) {
                url += "&search=" + encodeURIComponent(search);
            }

            if (props.category) {
                url += "&category=" + encodeURIComponent(props.category);
            }

            axiosInstance.get(url, config)
                .then(res => {
                    setData(res.data.entries);
                    setRowCountState(res.data.totalEntries);
                    setOptions(options => ({ ...options, categories: res.data.categoryOptions, searches: res.data.searchOptions, units: res.data.unitOptions, groupings: res.data.groupingOptions }));
                    setLoading(false);
                    setUpdating(false);
                    initialized.current = true;
                }).catch(err => {
                    setLoading(false);
                    setUpdating(false);
                    setSnackbarMessage("Unable to load inventory data");
                    setOpenSnackbar(true);
                });
        };

        if (props.loading || props.updating) {
            fetchData();
        }
    }, [props.loading, props.updating, setUpdating, userContext, setUserContext, location, props.paginationModel, setData, setRowCountState, props.category, search, setLoading, setSnackbarMessage, setOpenSnackbar, setOptions]);

    useEffect(() => {
        const fetchLocations = () => {
            const url = process.env.REACT_APP_API_URL + "/inventory/locations";
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            axiosInstance.get(url, config).then(res => setOptions(options => ({ ...options, locations: res.data }))).catch(err => { });
        };

        fetchLocations();
    }, [userContext, setUserContext, setOptions]);

    return (
        <DataFilter
            fields={fields}
            data={props.data}
            location={location}
            setLocation={setLocation}
            options={props.options}
            disableDates={true}
            search={search}
            setSearch={setSearch}
            enableSearch
            enableLocation
        />
    );
};

export default InventoryDataFilter;