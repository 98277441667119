import { Modal, Paper, Typography, Grid, IconButton } from "@mui/material";
import CloseDialog from "../Common/CloseDialog";
import { useState } from "react";
import CloseIcon from "@mui/icons-material/Close";

const ElModal = props => {
    const [openCloseDialog, setOpenCloseDialog] = useState(false);

    const handleCloseWithChange = () => {
        if (props.task.modified) {
            setOpenCloseDialog(true);
        } else {
            props.setTask({ ...props.task, open: false });
        }
    };

    const handleClose = () => props.setTask({ ...props.task, open: false, modified: false });

    return (
        <Modal open={props.task.open} onClose={handleCloseWithChange}>
            <Paper
                sx={{
                    position: "absolute",
                    top: "50%",
                    left: "50%",
                    transform: "translate(-50%, -50%)",
                    width: props.width ? props.width : "65%",
                    height: props.height ? props.height : "90%",
                    maxWidth: 1100,
                    bgcolor: "#d9d9d9",
                    overflow: "auto"
                }}
            >
                <Grid container item justifyContent="flex-end" sx={{ padding: "2.5% 2.5% 2.5% 0" }}>
                    <IconButton onClick={handleCloseWithChange}><CloseIcon /></IconButton>
                </Grid>
                <div style={{ padding: "0% 6% 6% 6%" }}>
                    <Typography variant="h5" sx={{ mb: 0.5 }}>{props.task.title}</Typography>
                    <Typography variant="body1" sx={{ mb: 2.5, color: "rgba(49, 54, 64, 0.6)" }}>{props.task.subtitle}</Typography>
                    {props.modalComponent}
                    {openCloseDialog && <CloseDialog open={openCloseDialog} setOpen={setOpenCloseDialog} handleClose={handleClose} />}
                </div>
            </Paper>
        </Modal>
    );
};

export default ElModal;