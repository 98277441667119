import { Typography, Paper, Tooltip, Grid, TextField, Autocomplete } from "@mui/material";
import dayjs from "dayjs";
import { DataGrid } from "@mui/x-data-grid";
import { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context/UserContext";
import { axiosInstance } from "../../utils/utils";

const LowStocks = props => {
    const cellParams = { style: { textOverflow: "ellipsis", overflow: "hidden" } };
    const [data, setData] = useState([]);
    const [userContext, setUserContext] = useContext(UserContext);
    const [location, setLocation] = useState(userContext.defaultLocation);
    const [locationOptions, setLocationOptions] = useState([]);

    const columns = [
        {
            field: "code",
            headerName: "Code",
            minWidth: 75,
            flex: 1.5,
            renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
        },
        {
            field: "quantity",
            headerName: "Qty.",
            type: "number",
            minWidth: 65,
            flex: 0.5,
            renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
        },
        {
            field: "date",
            headerName: "Last Added",
            minWidth: 110,
            flex: 0.5,
            valueGetter: params => dayjs(params.value).format("MM-DD HH:mm"),
            renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
        },
        {
            field: "description",
            headerName: "Description",
            minWidth: 115,
            flex: 5,
            renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
        },
        {
            field: "category",
            headerName: "Category",
            minWidth: 115,
            flex: 2.5,
            renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
        }
    ];

    useEffect(() => {
        const fetchData = () => {
            let url = process.env.REACT_APP_API_URL + "/inventory/lowstocks";
            url += location ? "?location=" + location : "";
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            axiosInstance.get(url, config).then(res => setData(res.data)).catch(err => { });
        };

        fetchData();
    }, [userContext, setUserContext, location]);

    useEffect(() => {
        const fetchLocations = () => {
            const url = process.env.REACT_APP_API_URL + "/inventory/locations";
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            axiosInstance.get(url, config).then(res => setLocationOptions(res.data)).catch(err => { });
        };

        fetchLocations();
    }, [userContext, setUserContext]);

    return (
        <Paper
            sx={{
                backgroundColor: "rgba(255, 255, 255, 0.1)",
                height: "100%",
                padding: "25px",
                boxShadow: "0px 2px 1px -1px rgba(0, 0, 0, 0.2), 0px 1px 1px 0px rgba(0, 0, 0, 0.14), 0px 1px 3px 0px rgba(0, 0, 0, 0.12)"
            }}
        >
            <Grid container alignItems="flex-start" justifyContent="space-between" sx={{ paddingBottom: "10px" }}>
                <Grid item>
                    <Typography sx={{ color: "#e76161", fontSize: "18px" }}>Low Stocks</Typography>
                </Grid>
                <Grid item>
                    <Autocomplete
                        options={locationOptions}
                        renderInput={params => (<TextField {...params} value={location} label="Location" />)}
                        onChange={(event, value) => setLocation(value)}
                        value={location}
                        sx={{ width: 200 }}
                        size="small"
                    />
                </Grid>
            </Grid>
            <DataGrid
                rows={data}
                columns={columns}
                initialState={{ pagination: { paginationModel: { pageSize: 5 } } }}
                pageSizeOptions={[5]}
                getRowHeight={() => "auto"}
                getRowId={row => row._id}
                disableRowSelectionOnClick
                disableColumnMenu
                sx={{
                    fontSize: 16,
                    border: 0,
                    paddingBottom: "25px",
                    "& .MuiDataGrid-cell": {
                        paddingTop: "12px",
                        paddingBottom: "12px",
                        display: "flex",
                        alignItems: "flex-start",
                        border: 0,
                        "&:focus-within, &:focus": { outline: "none" }
                    },
                    "& .MuiDataGrid-columnHeaders": { borderColor: "#bbbfca" },
                    "& .MuiDataGrid-iconSeparator": { color: "#bbbfca" },
                    "& .MuiDataGrid-footerContainer": { border: 0 },
                    "& .MuiDataGrid-overlay": { display: "none" }
                }}
            />
        </Paper>
    );
};

export default LowStocks;