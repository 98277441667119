import { useState, useEffect, useRef } from "react";
import Title from "../components/Common/Title";
import VocReportTable from "../components/VocReport/VocReportTable";
import VocReportDataFilter from "../components/VocReport/VocReportDataFilter";
import ElSnackbar from "../components/Common/ElSnackbar";
import { useOutletContext } from "react-router-dom";

const VocReport = () => {
    const [fetchedData, setFetchedData] = useState([]);
    const [processedData, setProcessedData] = useState([]);
    const [title, setTitle] = useState("");
    const topRef = useRef();
    const [rowCountState, setRowCountState] = useState(0);
    const [fetchedRowCountState, setFetchedRowCountState] = useState(0);
    const [loading, setLoading] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [groupedData, setGroupedData] = useState({});
    const [categories, setCategories] = useState([]);
    const [, setRightSidebar] = useOutletContext();

    useEffect(() => {
        setRightSidebar({ open: false });
    }, [setRightSidebar]);

    useEffect(() => {
        const calculateTotalUsed = () => {
            const updatedProcessedData = fetchedData.map(entry => {
                let parsedAmountPerUnit = parseFloat(entry.unit);
                let totalUsed = 0;
                let parsedUnit = "";

                if (!isNaN(parsedAmountPerUnit)) {
                    totalUsed = entry.quantity * parsedAmountPerUnit;
                    parsedUnit = entry.unit.slice(entry.unit.indexOf(parsedAmountPerUnit) + parsedAmountPerUnit.toString().length).trim();
                } else {
                    totalUsed = entry.quantity;
                    parsedUnit = entry.unit;
                }

                return { ...entry, totalUsed: +parseFloat(totalUsed).toFixed(2), parsedUnit: parsedUnit.toLowerCase() }
            });

            setProcessedData(updatedProcessedData);
        };

        calculateTotalUsed();
    }, [fetchedData]);

    useEffect(() => {
        const groupData = () => {
            const groups = new Set();

            processedData.forEach(entry => {
                groups.add(entry.grouping);
            });

            let updatedGroupedData = [];

            if (groups.has("") || groups.has(null)) {
                updatedGroupedData.push({ name: "Ungrouped", entries: [], totals: [] });
                groups.delete("");
                groups.delete(null);
            }

            Array.from(groups).forEach(group => {
                updatedGroupedData.push({ name: group, entries: [], totals: [] });
            });

            processedData.forEach(entry => {
                let index = -1;

                if (entry.grouping === null || entry.grouping === "") {
                    index = 0;
                } else {
                    index = updatedGroupedData.findIndex(group => group.name === entry.grouping);
                }

                updatedGroupedData[index].entries.push(entry);

                if (entry.grouping !== "" && entry.grouping !== null) {
                    const totalsIndex = updatedGroupedData[index].totals.findIndex(total => total.unit === entry.parsedUnit);

                    if (totalsIndex === -1) {
                        updatedGroupedData[index].totals.push({ unit: entry.parsedUnit, total: entry.totalUsed });
                    } else {
                        updatedGroupedData[index].totals[totalsIndex].total = updatedGroupedData[index].totals[totalsIndex].total + entry.totalUsed;
                    }
                }
            });

            setGroupedData(updatedGroupedData);
        };

        groupData();
    }, [processedData]);

    useEffect(() => {
        setRowCountState(fetchedRowCountState);
    }, [fetchedRowCountState]);

    return (
        <div ref={topRef}>
            <ElSnackbar open={openSnackbar} setOpen={setOpenSnackbar} message={snackbarMessage} />
            <Title title="VOC Report" subtitle="Generate VOC reports" />
            <VocReportDataFilter
                setTitle={setTitle}
                data={fetchedData}
                setData={setFetchedData}
                setRowCountState={setFetchedRowCountState}
                setSnackbarMessage={setSnackbarMessage}
                setOpenSnackbar={setOpenSnackbar}
                loading={loading}
                setLoading={setLoading}
                categories={categories}
                setCategories={setCategories}
            />
            <br />
            <VocReportTable
                data={groupedData}
                title={title}
                rowCountState={rowCountState}
                fetchedRowCountState={fetchedRowCountState}
                setRowCountState={setRowCountState}
                topRef={topRef}
                setSnackbarMessage={setSnackbarMessage}
                setOpenSnackbar={setOpenSnackbar}
                loading={loading}
                setLoading={setLoading}
                setCategories={setCategories}
            />
        </div>
    );
};

export default VocReport;