import DashboardIcon from "@mui/icons-material/Dashboard";
import ShoppingCartCheckoutIcon from "@mui/icons-material/ShoppingCartCheckout";
import ShoppingCartIcon from "@mui/icons-material/ShoppingCart";
import WarehouseIcon from "@mui/icons-material/Warehouse";
import AssessmentIcon from "@mui/icons-material/Assessment";
import SettingsIcon from "@mui/icons-material/Settings";
import ScienceIcon from "@mui/icons-material/Science";

const iconParams = { sx: { width: 31, height: 31 } };

const menuItems = [
    { name: "Dashboard", path: "/dashboard", icon: <DashboardIcon {...iconParams} /> },
    { name: "Check Out", path: "/checkout", icon: <ShoppingCartCheckoutIcon {...iconParams} /> },
    { name: "Add Stock", path: "/addstock", icon: <ShoppingCartIcon {...iconParams} /> },
    { name: "Inventory", path: "/inventory", icon: <WarehouseIcon {...iconParams} /> },
    { name: "Create Report", path: "/createreport", icon: <AssessmentIcon {...iconParams} /> },
    { name: "VOC Report", path: "/vocreport", icon: <ScienceIcon {...iconParams} /> },
    { name: "Settings", path: "/settings", icon: <SettingsIcon {...iconParams} /> }
];

export default menuItems;