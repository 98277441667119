import { Grid, TextField, Button } from "@mui/material";
import { useState, useEffect, useContext } from "react";
import ErrorBox from "../Common/ErrorBox";
import { UserContext } from "../../context/UserContext";
import { refreshToken } from "../../utils/utils";
import { axiosInstance } from "../../utils/utils";

const DefaultsFields = props => {
    const [userContext, setUserContext] = useContext(UserContext);
    const [inputs, setInputs] = useState({ defaultLocation: userContext.defaultLocation, defaultMinimumQuantity: userContext.defaultMinimumQuantity, defaultAdjustment: userContext.defaultAdjustment, notificationEmail: userContext.notificationEmail });
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [disableButton, setDisableButton] = useState(true);
    const gridItemParams = { sx: { width: props.gridItemWidth } };
    const textFieldParams = { fullWidth: true, variant: "outlined", onChange: event => handleChange(event.target.name, event.target.value) };

    const handleChange = (name, value) => setInputs(inputs => ({ ...inputs, [name]: value }));

    const handleSubmit = event => {
        event.preventDefault();
        setDisableButton(true);

        const url = process.env.REACT_APP_API_URL + "/users/" + userContext._id;
        const body = inputs;
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

        axiosInstance.post(url, body, config)
            .then(res => {
                props.setSnackbarMessage("Defaults updated")
                props.setOpenSnackbar(true);
                setErrorMessage("")
                setShowErrorMessage(false);
                refreshToken(setUserContext);
                document.activeElement.blur();
                setDisableButton(false);
            })
            .catch(err => {
                setErrorMessage(err.response.data);
                setShowErrorMessage(true);
                setDisableButton(false);
            });
    };

    useEffect(() => setDisableButton(!inputs.defaultMinimumQuantity || isNaN(inputs.defaultMinimumQuantity)), [inputs]);

    useEffect(() => {
        setInputs({ defaultLocation: userContext.defaultLocation, defaultMinimumQuantity: userContext.defaultMinimumQuantity, defaultAdjustment: userContext.defaultAdjustment, notificationEmail: userContext.notificationEmail });
    }, [userContext]);

    return (
        <form onSubmit={handleSubmit}>
            <Grid container item direction="column" spacing={2.5} sx={{ padding: "20px" }}>
                <Grid container item {...gridItemParams}>
                    <TextField {...textFieldParams} name="defaultLocation" label="Default location" value={inputs.defaultLocation} />
                </Grid>
                <Grid container item {...gridItemParams}>
                    <TextField {...textFieldParams} name="defaultMinimumQuantity" label="Default minimum quantity" value={inputs.defaultMinimumQuantity} />
                </Grid>
                <Grid container item {...gridItemParams}>
                    <TextField {...textFieldParams} name="defaultAdjustment" label="Default adjustment" value={inputs.defaultAdjustment} />
                </Grid>
                <Grid container item {...gridItemParams}>
                    <TextField {...textFieldParams} type="email" required={false} name="notificationEmail" label="Notification email" value={inputs.notificationEmail} />
                </Grid>
                <ErrorBox showErrorMessage={showErrorMessage} errorMessage={errorMessage} align={"left"} />
                <Grid container item {...gridItemParams}>
                    <Button disabled={disableButton} type="submit">Set</Button>
                </Grid>
            </Grid>
        </form>
    );
};

export default DefaultsFields;