import { Typography, Grid } from "@mui/material";
import { formatCurrency } from "../../../utils/utils";

const TotalEntriesAndCost = props => {
    return (
        <Grid container item spacing={1.5} justifyContent="flex-end" alignItems="baseline">
            <Grid item>
                <Typography sx={{ fontSize: "18px" }}><b>Entries:</b> {props.totalEntries}</Typography>
            </Grid>
            {props.totalCost !== undefined &&
                <Grid item>
                    <Typography sx={{ fontSize: "18px" }}><b>Total:</b> {formatCurrency(props.totalCost)}</Typography>
                </Grid>
            }
            {!isNaN(props.adjustment) && props.adjustment !== "" ?
                <Grid item>
                    <Typography sx={{ fontSize: "18px" }}><b>Final (+{props.adjustment}%):</b> {formatCurrency(props.adjustedTotalCost)}</Typography>
                </Grid>
                :
                null
            }
        </Grid>
    );
};

export default TotalEntriesAndCost;