import { Grid } from "@mui/material";

const FieldsOutline = props => {
    const width = props.width ? props.width : "100%";

    return (
        <fieldset style={{ fontSize: 20, border: "1px solid rgba(0, 0, 0, 0.2)", borderRadius: 5, width: width }}>
            <legend>{props.title}</legend>
            <Grid container item direction="column" spacing={2.5} sx={{ padding: "15px" }}>{props.children}</Grid>
        </fieldset>
    );
};

export default FieldsOutline;