import { useState, useRef, useEffect } from "react";
import Title from "../components/Common/Title";
import CheckoutsTable from "../components/CheckOut/CheckoutsTable";
import CheckoutsDataFilter from "../components/CheckOut/CheckoutsDataFilter";
import ElSnackbar from "../components/Common/ElSnackbar";
import { useOutletContext } from "react-router-dom";
import { Grid, IconButton } from "@mui/material";
import VerticalSplitIcon from "@mui/icons-material/VerticalSplit";
import FlipToFrontIcon from "@mui/icons-material/FlipToFront";

const CheckOut = props => {
    const storageHasDisableModal = window.localStorage.getItem("disableModal") !== null;
    const [rightSidebar, setRightSidebar] = useOutletContext();
    const [data, setData] = useState([]);
    const [options, setOptions] = useState({ projects: [], persons: [], locations: [], codes: [] });
    const topRef = useRef();
    const [rowCountState, setRowCountState] = useState(0);
    const [paginationModel, setPaginationModel] = useState({
        page: 0,
        pageSize: window.localStorage.getItem("checkoutsTablePageSize") !== null ? parseInt(window.localStorage.getItem("checkoutsTablePageSize")) : 50
    });
    const [totalCost, setTotalCost] = useState(0);
    const [loading, setLoading] = useState(true);
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [snackbarMessage, setSnackbarMessage] = useState("");
    const [project, setProject] = useState();
    const [location, setLocation] = useState();
    const [persons, setPersons] = useState([]);
    const [codes, setCodes] = useState([]);
    const [disableModal, setDisableModal] = useState(storageHasDisableModal ? JSON.parse(window.localStorage.getItem("disableModal")) : false);

    useEffect(() => {
        window.localStorage.setItem("disableModal", disableModal);
    }, [disableModal]);

    return (
        <div ref={topRef}>
            <ElSnackbar open={openSnackbar} setOpen={setOpenSnackbar} message={snackbarMessage} />
            <Title title="Check Out" subtitle="View and record checkouts" />
            <Grid container>
                <Grid container item xs={10}>
                    <CheckoutsDataFilter
                        data={data}
                        setData={setData}
                        setTotalCost={setTotalCost}
                        paginationModel={paginationModel}
                        setPaginationModel={setPaginationModel}
                        setRowCountState={setRowCountState}
                        setSnackbarMessage={setSnackbarMessage}
                        setOpenSnackbar={setOpenSnackbar}
                        loading={loading}
                        setLoading={setLoading}
                        options={options}
                        setOptions={setOptions}
                        project={project}
                        setProject={setProject}
                        location={location}
                        setLocation={setLocation}
                        persons={persons}
                        setPersons={setPersons}
                        codes={codes}
                        setCodes={setCodes}
                    />
                </Grid>
                {(!rightSidebar.task || !rightSidebar.task?.open) &&
                    <Grid container item xs={2} justifyContent="flex-end" alignContent={"flex-end"}>
                        <IconButton onClick={() => setDisableModal(false)} sx={{ backgroundColor: !disableModal ? "rgba(0, 0, 0, 0.1)" : "transparent", ":hover": { backgroundColor: !disableModal ? "rgba(0, 0, 0, 0.1)" : null } }}><FlipToFrontIcon /></IconButton>
                        <IconButton onClick={() => setDisableModal(true)} sx={{ backgroundColor: disableModal ? "rgba(0, 0, 0, 0.1)" : "transparent", ":hover": { backgroundColor: disableModal ? "rgba(0, 0, 0, 0.1)" : null } }}><VerticalSplitIcon /></IconButton>
                    </Grid>
                }
            </Grid>
            <br />
            <CheckoutsTable
                data={data}
                rowCountState={rowCountState}
                paginationModel={paginationModel}
                setPaginationModel={setPaginationModel}
                totalCost={totalCost}
                topRef={topRef}
                setSnackbarMessage={setSnackbarMessage}
                setOpenSnackbar={setOpenSnackbar}
                loading={loading}
                options={options}
                setOptions={setOptions}
                setProject={setProject}
                setLocation={setLocation}
                setPersons={setPersons}
                setCodes={setCodes}
                setRightSidebar={setRightSidebar}
                disableModal={disableModal}
            />
        </div>
    );
};

export default CheckOut;