import { Button, IconButton, Grid } from "@mui/material";
import { useState, useEffect, useContext, useRef } from "react";
import dayjs from "dayjs";
import FieldsOutline from "../../Common/FieldsOutline";
import DetailsFields from "../Fields/DetailsFields";
import EntryFields from "../Fields/EntryFields";
import DeleteEntryDialog from "../../Common/DeleteEntryDialog";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import ErrorBox from "../../Common/ErrorBox";
import NotesFields from "../../Common/NotesFields";
import { combineDateTime } from "../../../utils/utils";
import { UserContext } from "../../../context/UserContext";
import { axiosInstance } from "../../../utils/utils";

const EditModalComponent = props => {
    const [entry, setEntry] = useState({
        _id: "",
        date: dayjs(new Date()),
        time: dayjs(new Date()),
        location: "",
        vendor: "",
        reference: "",
        code: "",
        quantity: "",
        price: "",
        notes: "",
        description: ""
    });

    const [disableButton, setDisableButton] = useState(true);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [userContext, setUserContext] = useContext(UserContext);
    const [openDelete, setOpenDelete] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const loaded = useRef(false);

    const handleSubmit = event => {
        event.preventDefault();
        setDisableButton(true);

        const date = combineDateTime(entry.date, entry.time);

        const url = process.env.REACT_APP_API_URL + "/inventory/stocks/" + props.edit._id;
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };
        const body = { ...entry, date: date };

        body.code = body.code.toLowerCase();
        delete body.description;

        axiosInstance.post(url, body, config)
            .then(res => {
                props.setEdit({ ...props.edit, "open": false });
                props.setSnackbarMessage("Stock entry updated");
                props.setOpenSnackbar(true);
            })
            .catch(err => {
                setOpenDelete(false);
                setErrorMessage(err.response.data);
                setShowErrorMessage(true);
                setDisableButton(false);
            });
    };

    const handleDelete = () => {
        const url = process.env.REACT_APP_API_URL + "/inventory/stocks/" + props.edit._id;
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

        axiosInstance.delete(url, config)
            .then(res => {
                props.setEdit({ ...props.edit, "open": false });
                props.setSnackbarMessage("Stock entry deleted");
                props.setOpenSnackbar(true);
            })
            .catch(err => {
                setOpenDelete(false);
                setErrorMessage(err.response.data);
                setShowErrorMessage(true);
            });
    };

    const handleChange = (index, event) => setEntry(pairs => ({ ...pairs, [event.target.name]: event.target.value }));

    useEffect(() => {
        const fetchEntry = () => {
            const url = process.env.REACT_APP_API_URL + "/inventory/stocks/" + props.edit._id;
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            axiosInstance.get(url, config)
                .then(res => {
                    setEntry(entry => ({ ...entry, ...res.data, price: res.data.price.toFixed(2), date: dayjs(res.data.date), time: dayjs(res.data.date) }));
                })
                .catch(err => {
                    // Do nothing
                });
        };

        fetchEntry();
    }, [props.edit._id, userContext, setUserContext]);

    const setEdit = props.setEdit;

    useEffect(() => {
        const fieldsNotEmpty = () => {
            const requiredFields = ["date", "time", "location", "vendor", "reference", "code", "quantity", "price"];
            return requiredFields.every(field => entry[field]) && !isNaN(entry.quantity) && !isNaN(entry.price) && parseFloat(entry.price) === Math.round(((parseFloat(entry.price) + Number.EPSILON) * 100)) / 100;
        };

        if (!isLoading) {
            if (loaded.current) {
                setEdit(edit => ({ ...edit, modified: true }));
            } else {
                loaded.current = true;
            }
        }

        setDisableButton(!fieldsNotEmpty());
    }, [entry, isLoading, setEdit]);

    useEffect(() => setIsLoading(entry._id === ""), [entry]);

    return (!isLoading &&
        <form onSubmit={handleSubmit}>
            <Grid container direction="column" spacing={2.5}>
                <Grid container item>
                    <FieldsOutline title="Details">
                        <DetailsFields values={entry} setValues={setEntry} options={props.options} />
                    </FieldsOutline>
                </Grid>
                <Grid container item>
                    <FieldsOutline title="Item">
                        <EntryFields code={entry.code} quantity={entry.quantity} price={entry.price} description={entry.description} handleChange={handleChange} options={props.options} inventoryData={props.inventoryData} />
                    </FieldsOutline>
                </Grid>
                <Grid container item>
                    <FieldsOutline title="Notes">
                        <NotesFields values={entry} setValues={setEntry} />
                    </FieldsOutline>
                </Grid>
                <ErrorBox showErrorMessage={showErrorMessage} errorMessage={errorMessage} />
                <Grid container item>
                    <Grid container item xs={6} alignItems="center">
                        <IconButton onClick={() => setOpenDelete(true)}><DeleteOutlineIcon sx={{ fontSize: 30, color: "#495464" }} /></IconButton>
                    </Grid>
                    <Grid container item xs={6} justifyContent="flex-end" alignItems="center">
                        <Grid item sx={{ mr: 2.5 }}>
                            <Button variant="outlined" onClick={() => props.setEdit({ ...props.edit, open: false, modified: false })}>Cancel</Button>
                        </Grid>
                        <Grid item>
                            <Button
                                type="submit"
                                disabled={disableButton}
                                sx={{ backgroundColor: "#8bacaa", color: "#495464", "&:hover": { backgroundColor: "#8bacaa" } }}
                            >
                                Update
                            </Button>
                        </Grid>
                    </Grid>
                </Grid>
            </Grid>
            {openDelete && <DeleteEntryDialog open={openDelete} setOpen={setOpenDelete} handleDelete={handleDelete} />}
        </form>
    );
};

export default EditModalComponent;