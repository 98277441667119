import { useState, useEffect, useMemo, useCallback } from "react";
import dayjs from "dayjs";
import DataTable from "../DataTable/DataTable";
import { formatCurrency } from "../../utils/utils";
import DataTableFooter from "../DataTable/TableComponents/DataTableFooter";
import DataTableToolbar from "../DataTable/TableComponents/DataTableToolbar";
import TotalEntriesAndCost from "../DataTable/TableComponents/TotalEntriesAndCost";
import { Tooltip, Grid, Link } from "@mui/material";
import CreateReportToolbar from "./CreateReportToolbar";
import TableSummary from "../DataTable/TableComponents/TableSummary";
import { useGridApiRef } from "@mui/x-data-grid";

const CreateReportTable = props => {
    const [selectionIds, setSelectionIds] = useState([]);
    const [showSelected, setShowSelected] = useState(false);
    const [rows, setRows] = useState([]);
    const apiRef = useGridApiRef();

    const setProject = props.setProject;
    const setWork = props.setWork;
    const setPersons = props.setPersons;
    const setCodes = props.setCodes;

    const columns = useMemo(() => {
        const cellParams = { style: { textOverflow: "ellipsis", overflow: "hidden" } };

        const handleClick = (event, field, value) => {
            event.stopPropagation();

            if (field === "project") {
                setProject(value);
            } else if (field === "work") {
                setWork(value);
            } else if (field === "person") {
                setPersons([value]);
            } else if (field === "code") {
                setCodes([value]);
            }
        };

        return [
            {
                field: "code",
                headerName: "Code",
                flex: 1.4,
                renderCell: params => <Tooltip title={params.value}>
                    <div {...cellParams}>
                        <Link onClick={e => handleClick(e, "code", params.value)} sx={{ cursor: "pointer", textDecoration: "none", "&:hover": { textDecoration: "underline" } }}>
                            {params.value}
                        </Link>
                    </div>
                </Tooltip>
            },
            {
                field: "quantity",
                headerName: "Qty.",
                type: "number",
                minWidth: 60,
                flex: 0.5,
                renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
            },
            {
                field: "date",
                headerName: "Date",
                minWidth: 110,
                flex: 0.5,
                valueGetter: params => dayjs(params.value).format("MM-DD HH:mm"),
                renderCell: params => <Tooltip title={dayjs(params.row.date).format("M/D/YYYY hh:mm a")}><div {...cellParams}>{params.value}</div></Tooltip>
            },
            {
                field: "project",
                headerName: "Project",
                flex: 2,
                renderCell: params => <Tooltip title={params.value}>
                    <div {...cellParams}>
                        <Link onClick={e => handleClick(e, "project", params.value)} sx={{ cursor: "pointer", textDecoration: "none", "&:hover": { textDecoration: "underline" } }}>
                            {params.value}
                        </Link>
                    </div>
                </Tooltip>
            },
            {
                field: "work",
                headerName: "Work",
                flex: 2,
                renderCell: params => <Tooltip title={params.value}>
                    <div {...cellParams}>
                        <Link onClick={e => handleClick(e, "work", params.value)} sx={{ cursor: "pointer", textDecoration: "none", "&:hover": { textDecoration: "underline" } }}>
                            {params.value}
                        </Link>
                    </div>
                </Tooltip>
            },
            {
                field: "person",
                headerName: "Person",
                flex: 2,
                renderCell: params => <Tooltip title={params.value}>
                    <div {...cellParams}>
                        <Link onClick={e => handleClick(e, "person", params.value)} sx={{ cursor: "pointer", textDecoration: "none", "&:hover": { textDecoration: "underline" } }}>
                            {params.value}
                        </Link>
                    </div>
                </Tooltip>
            },
            {
                field: "location",
                headerName: "Location",
                flex: 2,
                renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
            },
            {
                field: "description",
                headerName: "Description",
                flex: 6,
                renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
            },
            {
                field: "price",
                headerName: "Price",
                type: "number",
                minWidth: 75,
                flex: 1,
                valueGetter: params => formatCurrency(params.value),
                renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
            },
            {
                field: "extended",
                headerName: "Extended",
                type: "number",
                minWidth: 85,
                flex: 1,
                valueGetter: params => formatCurrency(params.row.price * params.row.quantity),
                renderCell: params => <Tooltip title={params.value}><div {...cellParams}>{params.value}</div></Tooltip>
            }
        ];
    }, [setWork, setProject, setCodes, setPersons]);

    const setRowCountState = props.setRowCountState;
    const setTotalCost = props.setTotalCost;

    useEffect(() => {
        if (showSelected) {
            const idsSet = new Set(selectionIds);
            const selections = props.data.filter(entry => idsSet.has(entry._id));
            setTotalCost(selections.reduce((partialSum, entry) => partialSum + entry.price * entry.quantity, 0));
            setRowCountState(selections.length);
            setRows(selections);
        } else {
            setTotalCost(props.fetchedTotalCost);
            setRowCountState(props.fetchedRowCountState);
            setRows(props.data);
        }
    }, [showSelected, props.data, selectionIds, props.fetchedTotalCost, setTotalCost, setRowCountState, props.fetchedRowCountState]);

    const CustomFooter = useCallback(() => { return (<DataTableFooter rows={rows} />) }, [rows]);

    const CustomToolbar = useCallback(() => {
        const totalComponent = <TotalEntriesAndCost
            totalEntries={props.rowCountState}
            totalCost={props.totalCost}
            adjustment={props.adjustment}
            adjustedTotalCost={props.adjustedTotalCost}
        />;

        const toolbarComponent = <TableSummary totalComponent={totalComponent} title={props.title} columns={columns} />;

        return (<DataTableToolbar toolbarComponent={toolbarComponent} showToolbarContainer />)
    }, [props.rowCountState, props.totalCost, props.adjustment, props.adjustedTotalCost, columns, props.title]);

    const handlePageChange = useCallback(newPaginationModel => {
        props.setPaginationModel(newPaginationModel);
        window.localStorage.setItem("createReportTablePageSize", newPaginationModel.pageSize);

        if (props.topRef && props.topRef.current) {
            props.topRef.current.scrollIntoView()
        }
    }, [props]);

    useEffect(() => {
        if (selectionIds.length === 0) {
            setShowSelected(false);
        }
    }, [props.data, selectionIds]);

    return (
        <>
            <Grid container item sx={{ mb: 1.5 }}>
                <CreateReportToolbar
                    apiRef={apiRef}
                    selectionIds={selectionIds}
                    options={props.options}
                    setSnackbarMessage={props.setSnackbarMessage}
                    setOpenSnackbar={props.setOpenSnackbar}
                    setUpdating={props.setUpdating}
                    rowCountState={props.rowCountState}
                    totalCost={props.totalCost}
                    adjustedTotalCost={props.adjustedTotalCost}
                    adjustment={props.adjustment}
                    inventoryData={props.inventoryData}
                    setShowSelected={setShowSelected}
                    showSelected={showSelected}
                />
            </Grid>
            <Grid container item sx={{ border: "1px dashed rgba(0, 0, 0, 0.15)", backgroundColor: "rgba(0, 0, 0, 0.025)" }}>
                <Grid
                    item
                    sx={{
                        width: "11in",
                        backgroundColor: "rgba(255, 255, 255, 0.25)",
                        padding: "0.5in",
                        "& .MuiTypography-root": { color: "black" }
                    }}
                >
                    <DataTable
                        columns={columns}
                        rows={rows}
                        customFooter={CustomFooter}
                        customToolbar={CustomToolbar}
                        paginationMode="server"
                        paginationModel={props.paginationModel}
                        handlePageChange={handlePageChange}
                        rowCountState={props.rowCountState}
                        loading={props.loading}
                        setSelectionIds={setSelectionIds}
                        apiRef={apiRef}
                        selectedColumns={props.selectedColumns}
                        sx={{
                            fontSize: "13px",
                            color: "black",
                            border: 0,
                            "& .MuiTypography-root": { color: "black" },
                            "& .MuiDataGrid-cell": {
                                paddingTop: "5px",
                                paddingBottom: "5px",
                                display: "flex",
                                alignItems: "flex-start",
                                border: 0,
                                "&:focus-within, &:focus": { outline: "none" }
                            },
                            "& .MuiDataGrid-overlay": { bgcolor: "transparent" },
                            "& .MuiDataGrid-columnHeaders": { borderColor: "#bbbfca" },
                            "& .MuiDataGrid-iconSeparator": { color: "#bbbfca" },
                            "& .MuiDataGrid-footerContainer": { border: 0 },
                            "&.MuiDataGrid-root": { lineHeight: "normal" },
                            "& .MuiDataGrid-virtualScroller::-webkit-scrollbar": { display: "none" },
                            "& .MuiCheckbox-root": { padding: 0 },
                            "& .MuiTablePagination-selectLabel": { color: "black" },
                            "& .MuiInputBase-root": { color: "black" },
                            "& .MuiTablePagination-displayedRows": { color: "black" },
                            "& .MuiTablePagination-actions": { color: "black" },
                            "@media print": {
                                color: "#000000",
                                display: "table",
                                ".MuiDataGrid-footerContainer": { display: "none" },
                                "& .MuiDataGrid-virtualScroller": { display: "table", overflow: "visible" },
                                ".css-i4bv87-MuiSvgIcon-root": { color: "white" },
                                "@page": { size: "landscape" }
                            }
                        }}
                    />
                </Grid>
            </Grid>
        </>
    );
};

export default CreateReportTable;