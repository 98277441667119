import { Grid, Autocomplete, TextField, Divider } from "@mui/material";
import { LocalizationProvider, DatePicker, PickersDay } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import DateRangeIcon from "@mui/icons-material/DateRange";

const DataFilter = props => {
    const handleDateKeyDown = (event, name) => {
        if (event.key === "Enter") {
            event.preventDefault();
            props.setDates({ ...props.dates, [name]: dayjs(event.target.value) });
        }
    };

    const Day = dayProps => {
        const { day, outsideCurrentMonth, ...other } = dayProps;
        const isSelected = !props.outsideCurrentMonth && props.selectedDates.includes(day.format("YYYY-MM-DD"));

        return (<PickersDay {...other} outsideCurrentMonth={outsideCurrentMonth} day={day} selected={isSelected} />);
    };

    const handleChange = value => {
        if (props.selectedDates.includes(value.format("YYYY-MM-DD"))) {
            const updatedDates = props.selectedDates.filter(date => date !== value.format("YYYY-MM-DD"));
            props.setSelectedDates(updatedDates);
        } else {
            props.setSelectedDates([...props.selectedDates, value.format("YYYY-MM-DD")]);
        }
    };

    return (
        <Grid container direction="row" spacing={4} sx={{ alignItems: "flex-start" }}>
            {!props.disableDates &&
                <>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Grid item>
                            <DatePicker
                                label="From:"
                                defaultValue={props.dates.startDate}
                                onAccept={value => props.setDates({ ...props.dates, "startDate": value })}
                                sx={{ width: 170 }}
                                slotProps={{ textField: { onKeyDown: event => handleDateKeyDown(event, "startDate") } }}
                            />
                        </Grid>
                        <Grid item>
                            <DatePicker
                                label="To:"
                                defaultValue={props.dates.endDate}
                                onAccept={value => props.setDates({ ...props.dates, "endDate": value })}
                                sx={{ width: 170 }}
                                slotProps={{ textField: { onKeyDown: event => handleDateKeyDown(event, "endDate") } }}
                            />
                        </Grid>
                    </LocalizationProvider>
                    <Grid item><Divider orientation="vertical" sx={{ height: "56px" }} /></Grid>
                </>
            }
            {props.enableDateSelection &&
                <>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                        <Grid item>
                            <DatePicker
                                label="Select Dates"
                                onChange={handleChange}
                                minDate={props.dates.startDate}
                                maxDate={props.dates.endDate}
                                sx={{ width: 170 }}
                                slots={{ day: Day, openPickerIcon: DateRangeIcon }}
                                slotProps={{ textField: { inputProps: { readOnly: true, disabled: true } } }}
                                closeOnSelect={false}
                            />
                        </Grid>
                    </LocalizationProvider>
                    <Grid item><Divider orientation="vertical" sx={{ height: "56px" }} /></Grid>
                </>
            }
            {props.fields.map((field, index) => {
                if (field.multiple === true) {
                    return (
                        <Grid item key={index}>
                            <Autocomplete
                                options={field.options}
                                renderInput={params => (<TextField {...params} label={field.label} />)}
                                onChange={(event, values) => field.setState(values)}
                                value={field.state ? field.state : null}
                                autoHighlight
                                sx={{ minWidth: 200 }}
                                multiple={field.multiple}
                                filterSelectedOptions
                            />
                        </Grid>
                    );
                } else {
                    return (
                        <Grid item key={index}>
                            <Autocomplete
                                options={field.options}
                                renderInput={params => (<TextField {...params} label={field.label} />)}
                                onChange={(event, value) => field.setState(value)}
                                value={field.state ? field.state : null}
                                autoHighlight
                                sx={{ width: 200 }}
                            />
                        </Grid>
                    );
                }
            })}
            {props.enableSearch &&
                <>
                    <Grid item>
                        <Divider orientation="vertical" sx={{ height: "56px" }} />
                    </Grid>
                    <Grid item>
                        <Autocomplete
                            options={props.options.searches}
                            renderInput={params => (<TextField {...params} label="Search" />)}
                            onChange={(event, value) => props.setSearch(value)}
                            value={props.search ? props.search : null}
                            freeSolo
                            sx={{ width: 200 }}
                        />
                    </Grid>
                </>
            }
            {props.enableLocation &&
                <>
                    <Grid item><Divider orientation="vertical" sx={{ height: "56px" }} /></Grid>
                    <Grid item>
                        <Autocomplete
                            options={props.options.locations}
                            renderInput={params => (<TextField {...params} value={props.location} label="Location" />)}
                            onChange={(event, value) => props.setLocation(value)}
                            value={props.location ? props.location : null}
                            sx={{ width: 200 }}
                        />
                    </Grid>
                </>
            }
        </Grid>
    );
};

export default DataFilter;