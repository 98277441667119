import { Button } from "@mui/material";
import { Grid } from "@mui/material";
import { useState, useEffect, useContext, useRef } from "react";
import DetailsFields from "../Fields/DetailsFields";
import FieldsOutline from "../../Common/FieldsOutline";
import ErrorBox from "../../Common/ErrorBox";
import { UserContext } from "../../../context/UserContext";
import { axiosInstance } from "../../../utils/utils";

const AddModal = props => {
    const [userContext, setUserContext] = useContext(UserContext);
    const [image, setImage] = useState();
    const [disableButton, setDisableButton] = useState(true);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const loaded = useRef(false);

    const [details, setDetails] = useState(
        {
            category: "",
            code: "",
            description: "",
            unit: "Each",
            price: "",
            minimumQuantity: userContext.defaultMinimumQuantity,
            productNumber: "",
            voc: false,
            grouping: ""
        }
    );

    const handleSubmit = event => {
        event.preventDefault();
        setDisableButton(true);

        const url = process.env.REACT_APP_API_URL + "/inventory/items";
        const payload = details;
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

        axiosInstance.postForm(url, payload, config)
            .then(res => {
                props.setAdd({ ...props.add, "open": false });
                props.setSnackbarMessage("Inventory item added");
                props.setOpenSnackbar(true);
                props.setUpdating(true);
            })
            .catch(err => {
                setErrorMessage(err.response.data);
                setShowErrorMessage(true);
                setDisableButton(false);
            });
    };

    const setAdd = props.setAdd;

    useEffect(() => {
        const fieldsNotEmpty = () => {
            return (
                details.category
                && details.price
                && details.code
                && details.description
                && !isNaN(details.price)
                && details.minimumQuantity !== ""
                && !isNaN(details.minimumQuantity)
                && !/\s/.test(details.code)
            );
        };

        if (loaded.current) {
            setAdd(add => ({ ...add, modified: true }));
        } else {
            loaded.current = true;
        }

        setDisableButton(!fieldsNotEmpty());
    }, [details, setAdd]);

    return (
        <form onSubmit={handleSubmit}>
            <Grid container direction="column" spacing={2.5}>
                <Grid container item>
                    <FieldsOutline title="Details">
                        <DetailsFields values={details} setValues={setDetails} image={image} setImage={setImage} options={props.options} />
                    </FieldsOutline>
                </Grid>
                <ErrorBox showErrorMessage={showErrorMessage} errorMessage={errorMessage} />
                <Grid container item justifyContent="flex-end">
                    <Grid item sx={{ mr: 2.5 }}>
                        <Button variant="outlined" onClick={() => props.setAdd({ ...props.add, open: false, modified: false })}>Cancel</Button>
                    </Grid>
                    <Grid item>
                        <Button
                            type="submit"
                            disabled={disableButton}
                            sx={{ backgroundColor: "#8bacaa", color: "#495464", "&:hover": { backgroundColor: "#8bacaa" } }}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default AddModal;