import DataFilter from "../DataTable/DataFilter.js";
import { useState, useEffect, useContext, useRef } from "react";
import dayjs from "dayjs";
import { UserContext } from "../../context/UserContext";
import { axiosInstance } from "../../utils/utils.js";

const StocksDataFilter = props => {
    const [userContext, setUserContext] = useContext(UserContext);
    const [dates, setDates] = useState({
        startDate: userContext.stocksDataFilterStartDate !== undefined ? dayjs(userContext.stocksDataFilterStartDate) : dayjs(new Date()).subtract(1, "month"),
        endDate: userContext.stocksDataFilterEndDate !== undefined ? dayjs(userContext.stocksDataFilterEndDate) : dayjs(new Date())
    });
    const [updating, setUpdating] = useState(true);
    const [listening, setListening] = useState(false);
    const initialized = useRef(false);

    const setData = props.setData;
    const setRowCountState = props.setRowCountState;
    const setTotalCost = props.setTotalCost;
    const setLoading = props.setLoading;
    const setSnackbarMessage = props.setSnackbarMessage;
    const setOpenSnackbar = props.setOpenSnackbar;
    const setPaginationModel = props.setPaginationModel;
    const setOptions = props.setOptions;
    const setCodes = props.setCodes;
    const setLocation = props.setLocation;
    const setVendor = props.setVendor;

    const fields = [
        { name: "vendor", label: "Vendor", options: props.options.vendors, state: props.vendor, setState: setVendor },
        { name: "location", label: "Location", options: props.options.locations, state: props.location, setState: setLocation },
        { name: "code", label: "Code", options: props.options.codes, state: props.codes, setState: setCodes, multiple: true }
    ];

    useEffect(() => {
        if (dates.startDate) {
            setUserContext(userContext => ({ ...userContext, stocksDataFilterStartDate: dates.startDate }));
        }

        if (dates.endDate) {
            setUserContext(userContext => ({ ...userContext, stocksDataFilterEndDate: dates.endDate }));
        }
    }, [dates, setUserContext]);

    useEffect(() => {
        if (initialized.current) {
            setData([]);
            setLoading(true);
        }
    }, [setData, setLoading, props.paginationModel]);

    useEffect(() => {
        if (initialized.current) {
            setData([]);
            setPaginationModel(paginationModel => ({ ...paginationModel, page: 0 }));
        }
    }, [props.codes, setData, setPaginationModel]);

    useEffect(() => {
        if (initialized.current) {
            setCodes([]);
        }
    }, [props.location, props.vendor, dates, setOptions, setCodes]);

    useEffect(() => {
        if (!listening) {
            const eventSource = new EventSource(process.env.REACT_APP_API_URL + "/inventory/stocks/updates");

            eventSource.onmessage = event => {
                setUpdating(true);
            };

            return () => eventSource.close();
        }

        setListening(true);
    }, [listening]);

    useEffect(() => {
        const fetchData = () => {
            let url = process.env.REACT_APP_API_URL + "/inventory/stocks";
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            let query = "";

            if (Date.parse(dates.startDate) && Date.parse(dates.endDate)) {
                const startDate = new Date(dates.startDate.set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0)).toISOString().slice(0, 10);
                const endDate = new Date(dates.endDate.set("hour", 0).set("minute", 0).set("second", 0).set("millisecond", 0)).toISOString().slice(0, 10);
                query += "&start_date=" + startDate + "&end_date=" + endDate;
            }

            if (props.paginationModel) {
                query += "&page=" + props.paginationModel.page + "&limit=" + props.paginationModel.pageSize;
            }

            if (props.codes.length > 0) {
                query += "&codes=[";

                props.codes.forEach(code => {
                    query += '"' + encodeURIComponent(code) + '",';
                });

                query = query.slice(0, -1);
                query += "]";
            }

            if (props.location) {
                query += "&location=" + encodeURIComponent(props.location);
            }

            if (props.vendor) {
                query += "&vendor=" + encodeURIComponent(props.vendor);
            }

            if (query !== "") {
                url += "?" + query.slice(1);
            }

            axiosInstance.get(url, config)
                .then(res => {
                    setData(res.data.entries);
                    setRowCountState(res.data.totalEntries);
                    setTotalCost(res.data.totalCost);
                    setOptions(options => ({
                        ...options,
                        locations: res.data.locationOptions,
                        vendors: res.data.vendorOptions,
                        codes: props.codes.length > 0 ? options.codes : res.data.codeOptions
                    }));
                    setLoading(false);
                    setUpdating(false);
                    initialized.current = true;
                })
                .catch(err => {
                    setLoading(false);
                    setUpdating(false);
                    setSnackbarMessage("Unable to load stock data");
                    setOpenSnackbar(true);
                });
        };

        if (props.loading || updating) {
            fetchData();
        }
    }, [props.loading, updating, setUpdating, dates, userContext, setUserContext, setData, setRowCountState, setTotalCost, props.codes, props.location, props.vendor, props.paginationModel, setLoading, setSnackbarMessage, setOpenSnackbar, setOptions]);

    return (
        <DataFilter fields={fields} dates={dates} setDates={setDates} data={props.data} />
    );
};

export default StocksDataFilter;