import { useState, useEffect, useContext, useMemo } from "react";
import { Link } from "react-router-dom";
import { Grid, Paper, Button, Typography, Pagination } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";
import dayjs from "dayjs";
import ElModal from "../Common/ElModal";
import { formatCurrency } from "../../utils/utils";
import AddModalComponent from "./ModalComponents/AddModalComponent";
import EditModalComponent from "./ModalComponents/EditModalComponent";
import ImageModalComponent from "./ModalComponents/ImageModalComponent";
import TotalEntriesAndCost from "../DataTable/TableComponents/TotalEntriesAndCost";
import AddIcon from "@mui/icons-material/Add";
import { UserContext } from "../../context/UserContext";
import { Buffer } from "buffer";
import { axiosInstance } from "../../utils/utils";

const InventoryCards = props => {
    const [add, setAdd] = useState({ open: false, title: "Add inventory", subtitle: "Add a new inventory item", modified: false });
    const [edit, setEdit] = useState({ open: false, title: "Edit inventory", subtitle: "Modify the selected inventory entry", _id: "", modified: false });
    const [image, setImage] = useState({ open: false, title: "", subtitle: "", image: null });
    const [images, setImages] = useState({});
    const [userContext, setUserContext] = useContext(UserContext);

    const setCategory = props.setCategory;
    const setRightSidebar = props.setRightSidebar;
    const setUpdating = props.setUpdating;

    const handlePageChange = page => {
        props.setPaginationModel(paginationModel => ({ ...paginationModel, page: page }));

        if (props.topRef && props.topRef.current) {
            props.topRef.current.scrollIntoView();
        }
    };

    useEffect(() => {
        props.data.forEach((item, index) => {
            const url = process.env.REACT_APP_API_URL + "/inventory/items/images/" + item.image;
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            if (item.image) {
                axiosInstance.get(url, config)
                    .then(res => {
                        const base64Image = Buffer.from(res.data, "binary").toString("base64");
                        setImages(images => ({ ...images, [index]: `data:image/*;base64,${base64Image}` }));
                    })
                    .catch(err => {
                        setImages(images => ({ ...images, [index]: null }));
                    });
            } else {
                setImages(images => ({ ...images, [index]: null }));
            }
        });
    }, [props.data, userContext, setUserContext]);

    const addModalComponent = useMemo(() => <AddModalComponent
        add={add}
        setAdd={setAdd}
        setSnackbarMessage={props.setSnackbarMessage}
        setOpenSnackbar={props.setOpenSnackbar}
        options={props.options}
        setUpdating={setUpdating}
    />, [add, setUpdating, props.options, props.setSnackbarMessage, props.setOpenSnackbar]);

    const editModalComponent = useMemo(() => <EditModalComponent
        edit={edit}
        setEdit={setEdit}
        setSnackbarMessage={props.setSnackbarMessage}
        setOpenSnackbar={props.setOpenSnackbar}
        options={props.options}
        setUpdating={setUpdating}
    />, [edit, setUpdating, setEdit, props.options, props.setSnackbarMessage, props.setOpenSnackbar]);

    const imagetModalComponent = <ImageModalComponent image={image.image} />;

    const handleClick = (event, item) => {
        if (event.target.tagName === "path" || event.target.tagName === "svg" || event.target.tagName === "IMG") {
            return;
        } else if (userContext._id === item.user || userContext.admin) {
            setEdit({ ...edit, open: true, _id: item._id });
        }
    };

    const handleClickFilterItem = (event, field, value) => {
        event.stopPropagation();

        if (field === "category") {
            setCategory(value);
        }
    };

    useEffect(() => {
        if (props.disableModal) {
            setRightSidebar(rightSidebar => ({ ...rightSidebar, component: addModalComponent, task: add, setTask: setAdd }));
        }
    }, [setRightSidebar, add, addModalComponent, props.disableModal]);

    useEffect(() => {
        if (props.disableModal) {
            setRightSidebar(rightSidebar => ({ ...rightSidebar, component: editModalComponent, task: edit, setTask: setEdit }));
        }
    }, [setRightSidebar, edit, editModalComponent, props.disableModal, setEdit]);

    return (
        <Grid container item direction="column" sx={{ width: props.width }}>
            <Grid container item alignItems="center">
                <Grid container item xs={6}>
                    <Button
                        onClick={() => setAdd({ ...add, open: true })}
                        size="medium"
                        sx={{ backgroundColor: "#8bacaa", color: "#495464", "&:hover": { backgroundColor: "#8bacaa" } }}
                    >
                        <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><AddIcon sx={{ fontSize: 20 }} /></span>
                        Item
                    </Button>
                </Grid>
                <Grid item xs={6}><TotalEntriesAndCost totalEntries={props.rowCountState} /></Grid>
            </Grid>
            <Grid item sx={{ marginTop: 2.5 }}>
                {props.loading ?
                    <div style={{ display: "flex", justifyContent: "center", height: "65vh", alignItems: "center" }}>
                        <Typography>Loading data...</Typography>
                    </div>
                    :
                    props.data.map((item, index) => {
                        return (
                            <Paper
                                key={index}
                                sx={{
                                    backgroundColor: "rgba(255, 255, 255, 0.25)",
                                    minHeight: "250px",
                                    marginBottom: "20px",
                                    padding: "25px",
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                    boxShadow: "none"
                                }}
                                onClick={event => handleClick(event, item)}
                            >
                                <Grid container direction="row" wrap="nowrap">
                                    <Grid container item spacing={2.5} sx={{ marginRight: "40px" }} wrap="nowrap">
                                        <Grid item justifyContent="center" xs={2} sx={{ minWidth: "200px", marginRight: "40px" }}>
                                            <div style={{ width: "200px", height: "200px", borderRadius: "25px" }}>
                                                {images[index] ?
                                                    <Link onClick={() => setImage(image => ({ ...image, open: true, image: images[index], title: item.description, subtitle: item.code }))}>
                                                        <img src={images[index]} alt="" style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "5px" }} />
                                                    </Link>
                                                    :
                                                    <ImageIcon viewBox="3 3 18 18" sx={{ width: "100%", height: "100%", color: "rgba(0, 0, 0, 0.1)" }} />
                                                }
                                            </div>
                                        </Grid>
                                        <Grid item>
                                            <Grid item>
                                                <Typography variant="h6">{item.description}</Typography>
                                                <Typography>{item.code}</Typography>
                                            </Grid>
                                            <Grid item>
                                                <Typography sx={{ fontWeight: 450 }}>{item.quantity} in stock</Typography>
                                                <Typography>{formatCurrency(item.price)}</Typography>
                                            </Grid>
                                        </Grid>
                                    </Grid>
                                    <Grid container item direction="column" justifyContent="space-between" xs={3}>
                                        <Grid item sx={{ marginBottom: "40px" }}>
                                            <Typography><b>Unit:</b> {item.unit ? item.unit : "N/A"}</Typography>
                                            <Typography><b>Product number:</b> {item.productNumber ? item.productNumber : "N/A"}</Typography>
                                            <Typography><b>Category:</b> <Link onClick={e => handleClickFilterItem(e, "category", item.category)} style={{ cursor: "pointer", textDecoration: "none" }}>{item.category}</Link></Typography>
                                            <Typography><b>Minimum quantity:</b> {item.minimumQuantity}</Typography>
                                            <Typography><b>Grouping:</b> {item.grouping ? item.grouping : "N/A"}</Typography>
                                            <Typography>{item.voc ? "VOC" : ""}</Typography>
                                        </Grid>
                                        <Grid item>
                                            <Typography variant="body2" sx={{ color: "rgba(0, 0, 0, 0.25)", fontStyle: "italic" }}>
                                                Updated {dayjs(item.updatedAt).format("MMM DD, YYYY")}
                                            </Typography>
                                        </Grid>
                                    </Grid>
                                </Grid>
                            </Paper>
                        )
                    })
                }
            </Grid>
            <Grid container item justifyContent="center">
                <Pagination
                    count={props.paginationModel?.pageSize ? Math.floor(props.rowCountState / props.paginationModel.pageSize) : null}
                    page={props.paginationModel?.page ? props.paginationModel.page + 1 : 1}
                    showFirstButton
                    showLastButton
                    onChange={(event, page) => handlePageChange(page - 1)}
                />
            </Grid>
            {add.open && !props.disableModal && <ElModal task={add} setTask={setAdd} modalComponent={addModalComponent} />}
            {edit.open && !props.disableModal && <ElModal task={edit} setTask={setEdit} modalComponent={editModalComponent} />}
            {image.open && <ElModal task={image} setTask={setImage} modalComponent={imagetModalComponent} />}
        </Grid >
    );
};

export default InventoryCards;