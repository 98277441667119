import { Grid, TextField, InputAdornment, Button, Checkbox, FormControlLabel, Typography, Autocomplete, createFilterOptions } from "@mui/material";
import ImageIcon from "@mui/icons-material/Image";

const DetailsFields = props => {
    const filterOptions = createFilterOptions({ matchFrom: "start" });
    const autocompleteParams = { fullWidth: true, freeSolo: true, autoSelect: true, autoComplete: true, autoHighlight: true, filterOptions: filterOptions };
    const textFieldParams = { type: "text", fullWidth: true, variant: "outlined", onChange: event => handleChange(event.target.name, event.target.value) };

    const handleChange = (name, value) => props.setValues(pairs => ({ ...pairs, [name]: value }));

    const handleImageChange = (event, value) => {
        handleChange("image", event.target.files[0]);
        props.setImage(URL.createObjectURL(event.target.files[0]));
    };

    return (
        <Grid container item spacing={2.5}>
            <Grid container item direction="column" spacing={2.5} xs={5.5} sx={{ paddingRight: "15px" }}>
                <Grid container item>
                    <div style={{ width: "100%", aspectRatio: 1, borderRadius: "5px" }}>
                        {props.image ?
                            <img src={props.image} alt="" style={{ width: "100%", height: "100%", objectFit: "cover", borderRadius: "5px" }} />
                            :
                            <ImageIcon viewBox="3 3 18 18" sx={{ width: "100%", height: "100%" }} />
                        }
                    </div>
                </Grid>
                <Grid container item alignItems="center" justifyContent="space-between">
                    <Grid item>
                        <label>
                            <input hidden type="file" accept="image/*" onChange={handleImageChange} />
                            <Button color="secondary" variant="contained" component="span">Upload</Button>
                        </label>
                    </Grid>
                    <Grid item>
                        <FormControlLabel control={<Checkbox />} label="Remove" onChange={(event, value) => handleChange("removeImage", value)} />
                    </Grid>
                </Grid>
            </Grid>
            <Grid container item direction="column" spacing={2.5} xs={6.5}>
                <Grid container item>
                    <Autocomplete
                        {...autocompleteParams}
                        options={props.options.categories}
                        renderInput={params => (<TextField {...params} label="Category" autoFocus />)}
                        onInputChange={(event, value) => handleChange("category", value)}
                        inputValue={props.values.category}
                    />
                </Grid>
                <Grid container item><TextField {...textFieldParams} name="code" label="Code" value={props.values.code} /></Grid>
                <Grid container item>
                    <TextField {...textFieldParams} name="description" label="Description" multiline rows={4} value={props.values.description} />
                </Grid>
                <Grid container item>
                    <Autocomplete
                        {...autocompleteParams}
                        options={props.options.units}
                        renderInput={params => (<TextField {...params} label="Unit" />)}
                        onInputChange={(event, value) => handleChange("unit", value)}
                        inputValue={props.values.unit}
                    />
                </Grid>
                <Grid container item spacing={2.5}>
                    <Grid container item xs={6}>
                        <TextField
                            {...textFieldParams}
                            name="price"
                            label="Price"
                            value={props.values.price}
                            sx={{ width: "100%" }}
                            InputProps={{ startAdornment: <InputAdornment position="start">$</InputAdornment> }}
                        />
                    </Grid>
                    <Grid container item xs={6}>
                        <TextField {...textFieldParams} name="minimumQuantity" label="Min qty." value={props.values.minimumQuantity} />
                    </Grid>
                </Grid>
                <Grid container item>
                    <Typography>Optional</Typography>
                </Grid>
                <Grid container item>
                    <TextField {...textFieldParams} name="productNumber" label="Product number" value={props.values.productNumber} />
                </Grid>
                <Grid container item>
                    <Autocomplete
                        {...autocompleteParams}
                        options={props.options.groupings}
                        renderInput={params => (<TextField {...params} label="Grouping" />)}
                        onInputChange={(event, value) => handleChange("grouping", value)}
                        inputValue={props.values.grouping}
                    />
                </Grid>
                <Grid container item>
                    <FormControlLabel control={<Checkbox />} label="VOC" checked={props.values.voc} onChange={(event, value) => handleChange("voc", value)} />
                </Grid>
            </Grid>
        </Grid>
    );
};

export default DetailsFields;