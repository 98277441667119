import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material";

const DeleteEntryDialog = props => {
    const handleClose = () => { props.setOpen(false) };

    return (
        <Dialog open={props.open} onClose={handleClose} PaperProps={{ style: { backgroundColor: "#495464", padding: 30 } }}>
            <DialogTitle color="#d9d9d9">Confirm Delete</DialogTitle>
            <DialogContent><DialogContentText>Are you sure you want to delete?</DialogContentText></DialogContent>
            <DialogActions>
                <Button onClick={handleClose} sx={{ backgroundColor: "transparent", color: "#d9d9d9", "&:hover": { backgroundColor: "rgba(0, 0, 0, 0.1)" } }}>
                    Cancel
                </Button>
                <Button onClick={props.handleDelete} sx={{ backgroundColor: "#e76161", color: "#d9d9d9", "&:hover": { backgroundColor: "#e76161" } }}>
                    Delete
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default DeleteEntryDialog;