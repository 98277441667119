import { useState, useMemo, useCallback } from "react";
import DataTable from "../DataTable/DataTable";
import DataTableFooter from "../DataTable/TableComponents/DataTableFooter";
import DataTableToolbar from "../DataTable/TableComponents/DataTableToolbar";
import TotalEntriesAndCost from "../DataTable/TableComponents/TotalEntriesAndCost";
import { useGridApiRef } from "@mui/x-data-grid";
import ProjectsToolbar from "./ProjectsToolbar";
import TableSummary from "../DataTable/TableComponents/TableSummary";

const ProjectsTable = props => {
    const [edit, setEdit] = useState({ open: false, title: "Edit checkout", subtitle: "Modify the selected checkout entry", _id: "", modified: false });
    const rows = useMemo(() => props.data, [props.data]);
    const apiRef = useGridApiRef();

    const columns = useMemo(() => {
        const cellParams = { style: { textOverflow: "ellipsis", overflow: "hidden" } };

        return [
            {
                field: "project",
                headerName: "Project",
                minWidth: 500,
                flex: 1,
                renderCell: params => <div {...cellParams}>{params.value}</div>
            }
        ];
    }, []);

    const CustomFooter = useCallback(() => { return (<DataTableFooter rows={rows} />) }, [rows]);

    const CustomToolbar = useCallback(() => {
        const totalComponent = <TotalEntriesAndCost totalEntries={props.rowCountState} />;
        const toolbarComponent = <TableSummary totalComponent={totalComponent} title="List of Projects" columns={columns} />;

        return (<DataTableToolbar toolbarComponent={toolbarComponent} />)
    }, [props.rowCountState, columns]);

    const handlePageChange = useCallback(newPaginationModel => {
        props.setPaginationModel(newPaginationModel);
        window.localStorage.setItem("projectsTablePageSize", newPaginationModel.pageSize);

        if (props.topRef && props.topRef.current) {
            props.topRef.current.scrollIntoView()
        }
    }, [props]);

    return (
        <div>
            <ProjectsToolbar
                apiRef={apiRef}
                edit={edit}
                setEdit={setEdit}
                options={props.options}
                setSnackbarMessage={props.setSnackbarMessage}
                setOpenSnackbar={props.setOpenSnackbar}
                rowCountState={props.rowCountState}
            />
            <DataTable
                columns={columns}
                rows={rows}
                customFooter={CustomFooter}
                customToolbar={CustomToolbar}
                paginationMode="server"
                paginationModel={props.paginationModel}
                handlePageChange={handlePageChange}
                rowCountState={props.rowCountState}
                loading={props.loading}
                apiRef={apiRef}
            />
        </div>
    );
};

export default ProjectsTable;