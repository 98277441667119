import { Button, Grid } from "@mui/material";
import { useState, useEffect, useContext, useRef } from "react";
import dayjs from "dayjs";
import DetailsFields from "../Fields/DetailsFields";
import FieldsOutline from "../../Common/FieldsOutline";
import AddEntriesFields from "../Fields/EntriesFields";
import { combineDateTime } from "../../../utils/utils";
import ErrorBox from "../../Common/ErrorBox";
import NotesFields from "../../Common/NotesFields";
import { UserContext } from "../../../context/UserContext";
import { axiosInstance } from "../../../utils/utils";

const AddModal = props => {
    const [userContext, setUserContext] = useContext(UserContext);
    const [entries, setEntries] = useState([{ code: "", quantity: 1, description: "" }]);
    const [disableButton, setDisableButton] = useState(false);
    const [showErrorMessage, setShowErrorMessage] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const loaded = useRef(false);

    const [details, setDetails] = useState(
        {
            date: dayjs(new Date()),
            time: dayjs(new Date()),
            project: "",
            person: "",
            location: userContext.defaultLocation ? userContext.defaultLocation : "",
            work: "",
            notes: ""
        }
    );

    const handleSubmit = event => {
        event.preventDefault();
        setDisableButton(true);

        const date = combineDateTime(details.date, details.time);

        let body = [];

        for (const entry of entries) {
            if (entry.code) {
                const modifiedEntry = { ...entry };
                modifiedEntry.code = modifiedEntry.code.toLowerCase();
                delete modifiedEntry.description;
                body.push({ ...details, ...modifiedEntry, date: date });
            }
        }

        const url = process.env.REACT_APP_API_URL + "/inventory/checkouts";
        const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

        axiosInstance.post(url, body, config)
            .then(res => {
                props.setAdd({ ...props.add, "open": false });
                props.setSnackbarMessage("Checkout entries added");
                props.setOpenSnackbar(true);
            })
            .catch(err => {
                setErrorMessage(err.response.data);
                setShowErrorMessage(true);
                setDisableButton(false);
            });
    };

    const setAdd = props.setAdd;

    useEffect(() => {
        const fieldsNotEmpty = () => {
            const requiredDetails = { ...details };
            delete requiredDetails["notes"];
            delete requiredDetails["work"];

            let numValidEntries = 0;

            for (const entry of entries) {
                if (entry.code) {
                    if (entry.quantity && !isNaN(entry.quantity)) {
                        numValidEntries++;
                    } else {
                        return false;
                    }
                }
            }

            if (numValidEntries === 0) {
                return false;
            }

            return Object.values(requiredDetails).every(value => value);
        };

        if (loaded.current) {
            setAdd(add => ({ ...add, modified: true }));
        } else {
            loaded.current = true;
        }

        setDisableButton(!fieldsNotEmpty());
    }, [details, entries, setAdd]);

    return (
        <form onSubmit={handleSubmit}>
            <Grid container direction="column" spacing={2.5}>
                <Grid container item>
                    <FieldsOutline title="Details">
                        <DetailsFields values={details} setValues={setDetails} options={props.options} />
                    </FieldsOutline>
                </Grid>
                <Grid container item>
                    <FieldsOutline title="Entries">
                        <AddEntriesFields entries={entries} setEntries={setEntries} options={props.options} inventoryData={props.inventoryData} />
                    </FieldsOutline>
                </Grid>
                <Grid container item>
                    <FieldsOutline title="Notes">
                        <NotesFields values={details} setValues={setDetails} />
                    </FieldsOutline>
                </Grid>
                <ErrorBox showErrorMessage={showErrorMessage} errorMessage={errorMessage} />
                <Grid container item justifyContent="flex-end">
                    <Grid item sx={{ mr: 2.5 }}>
                        <Button variant="outlined" onClick={() => props.setAdd({ ...props.add, open: false, modified: false })}>Cancel</Button>
                    </Grid>
                    <Grid item>
                        <Button
                            type="submit"
                            disabled={disableButton}
                            sx={{ backgroundColor: "#8bacaa", color: "#495464", "&:hover": { backgroundColor: "#8bacaa" } }}
                        >
                            Submit
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </form>
    );
};

export default AddModal;