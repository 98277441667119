import { useState, useMemo, useEffect } from "react";
import ElModal from "../Common/ElModal";
import AddModalComponent from "./ModalComponents/AddModalComponent";
import EditModalComponent from "./ModalComponents/EditModalComponent";
import EditMultipleModalComponent from "./ModalComponents/EditMultipleModalComponent";
import TotalEntriesAndCost from "../DataTable/TableComponents/TotalEntriesAndCost";
import { Button } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import PrintIcon from "@mui/icons-material/Print";
import { Grid } from "@mui/material";

const InventoryToolbar = (props) => {
    const [add, setAdd] = useState({ open: false, title: "Add item", subtitle: "Add a new inventory item", modified: false });
    const [editMultiple, setEditMultiple] = useState({ open: false, title: "Edit items", subtitle: "Modify the selected inventory items", _id: "", modified: false });

    const setRightSidebar = props.setRightSidebar;
    const setEdit = props.setEdit;
    const setUpdating = props.setUpdating;

    const totalComponent = <TotalEntriesAndCost totalEntries={props.rowCountState} />;

    const addModalComponent = useMemo(() => <AddModalComponent
        add={add}
        setAdd={setAdd}
        setSnackbarMessage={props.setSnackbarMessage}
        setOpenSnackbar={props.setOpenSnackbar}
        options={props.options}
        setUpdating={setUpdating}
    />, [add, setUpdating, props.options, props.setSnackbarMessage, props.setOpenSnackbar]);

    const editModalComponent = useMemo(() => <EditModalComponent
        edit={props.edit}
        setEdit={setEdit}
        setSnackbarMessage={props.setSnackbarMessage}
        setOpenSnackbar={props.setOpenSnackbar}
        options={props.options}
        setUpdating={setUpdating}
    />, [props.edit, setUpdating, setEdit, props.options, props.setSnackbarMessage, props.setOpenSnackbar]);

    const editMultipleModalComponent = useMemo(() => <EditMultipleModalComponent
        editMultiple={editMultiple}
        setEditMultiple={setEditMultiple}
        setSnackbarMessage={props.setSnackbarMessage}
        setOpenSnackbar={props.setOpenSnackbar}
        options={props.options}
        setUpdating={setUpdating}
        selectionIds={props.selectionIds}
    />, [editMultiple, setUpdating, props.selectionIds, props.options, props.setSnackbarMessage, props.setOpenSnackbar]);

    useEffect(() => {
        if (props.disableModal) {
            setRightSidebar(rightSidebar => ({ ...rightSidebar, component: addModalComponent, task: add, setTask: setAdd }));
        }
    }, [setRightSidebar, add, addModalComponent, props.disableModal]);

    useEffect(() => {
        if (props.disableModal) {
            setRightSidebar(rightSidebar => ({ ...rightSidebar, component: editModalComponent, task: props.edit, setTask: setEdit }));
        }
    }, [setRightSidebar, props.edit, editModalComponent, props.disableModal, setEdit]);

    useEffect(() => {
        if (props.disableModal) {
            setRightSidebar(rightSidebar => ({ ...rightSidebar, component: editMultipleModalComponent, task: editMultiple, setTask: setEditMultiple }));
        }
    }, [setRightSidebar, editMultiple, editMultipleModalComponent, props.disableModal]);

    return (
        <Grid container wrap="nowrap">
            <Grid container item spacing={2} alignItems="center" justifyContent="flex-start">
                <Grid item>
                    <Button onClick={() => props.apiRef.current.exportDataAsPrint()} size="medium">
                        <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><PrintIcon sx={{ fontSize: 20 }} /></span>
                        Print
                    </Button>
                </Grid>
                <Grid item>
                    <Button
                        onClick={() => setAdd({ ...add, open: true })}
                        size="medium"
                        sx={{ backgroundColor: "#8bacaa", color: "#495464", "&:hover": { backgroundColor: "#8bacaa" } }}
                    >
                        <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><AddIcon sx={{ fontSize: 20 }} /></span>
                        Add
                    </Button>
                </Grid>
                {props.selectionIds.length > 0 &&
                    <Grid item>
                        <Button
                            onClick={() => setEditMultiple({ ...editMultiple, open: true })}
                            size="medium"
                            sx={{ backgroundColor: "#5e6673", color: "#bbbfca", "&:hover": { backgroundColor: "#5e6673" } }}
                        >
                            <span style={{ marginLeft: "-4px", marginRight: "8px", display: "inherit" }}><EditIcon sx={{ fontSize: 20 }} /></span>
                            Edit
                        </Button>
                    </Grid>
                }
            </Grid>
            <Grid container item>{totalComponent}</Grid>
            {add.open && !props.disableModal && <ElModal task={add} setTask={setAdd} modalComponent={addModalComponent} />}
            {props.edit.open && !props.disableModal && <ElModal task={props.edit} setTask={props.setEdit} modalComponent={editModalComponent} />}
            {editMultiple.open && !props.disableModal && <ElModal task={editMultiple} setTask={setEditMultiple} modalComponent={editMultipleModalComponent} />}
        </Grid>
    );
};

export default InventoryToolbar;