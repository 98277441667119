import { Typography } from "@mui/material";
import { useContext } from "react";
import { UserContext } from "../../context/UserContext";

const Greeting = () => {
    const [userContext] = useContext(UserContext);

    const showGreeting = () => {
        let hour = (new Date()).getHours();
        let greeting = "";

        if (hour < 12) {
            greeting = "Good morning";
        } else if (hour < 18) {
            greeting = "Good afternoon";
        } else {
            greeting = "Good evening";
        }

        greeting += ", " + userContext.username + "!";

        return greeting;
    };

    return (
        <Typography variant="body1" color="#000000">
            {userContext.username && showGreeting()}
        </Typography>
    );
};

export default Greeting;