import { TextField, InputAdornment } from "@mui/material";

const PriceAdjuster = props => {
    const handleKeyDown = event => {
        if (event.keyCode === 13) {
            event.preventDefault();
            props.setAdjustment(event.target.value);
        }
    };

    return (
        <TextField
            type="text"
            name="adjustment"
            label="ADJ"
            onKeyDown={handleKeyDown}
            defaultValue={props.adjustment}
            sx={{ width: 100 }}
            variant="outlined"
            size="small"
            InputProps={{ endAdornment: <InputAdornment position="end">%</InputAdornment> }}
        />
    );
};

export default PriceAdjuster;