import { Typography } from "@mui/material";
import dayjs from "dayjs";
import { useState, useEffect, useContext } from "react";
import { UserContext } from "../../context/UserContext";
import { axiosInstance } from "../../utils/utils";

const LastCheckout = () => {
    const [data, setData] = useState(null);
    const [userContext, setUserContext] = useContext(UserContext);

    useEffect(() => {
        const fetchData = () => {
            const url = process.env.REACT_APP_API_URL + "/inventory/lastcheckout";
            const config = { headers: { Authorization: `Bearer ${userContext.token}` }, userContext: userContext, setUserContext: setUserContext };

            axiosInstance.get(url, config).then(res => setData(res.data)).catch(err => { });
        };

        fetchData();
    }, [userContext, setUserContext]);

    return (data &&
        <Typography variant="body1" color="#000000">
            The last checkout was
            <span style={{ color: "#e76161" }}> {data.description} </span>
            from
            <span style={{ color: "#8bacaa" }}> {data.person} </span>
            for
            <span style={{ color: "#f99b7d" }}> {data.project} </span>
            on {dayjs(data.date).format("MMMM DD, YYYY")} at {dayjs(data.date).format("hh:mm A")}.
        </Typography>
    );
};

export default LastCheckout;