import './App.css';
import { Routes, Route, Navigate, useLocation } from 'react-router-dom';
import { CssBaseline, ThemeProvider } from '@mui/material';
import { useContext, useEffect, useCallback } from 'react';
import { UserContext } from './context/UserContext';
import axios from 'axios';
import theme from './themes/theme';
import Login from './pages/Login';
import Dashboard from './pages/Dashboard';
import AddStock from './pages/AddStock';
import Inventory from './pages/Inventory';
import Projects from './pages/Projects';
import CheckOut from './pages/CheckOut';
import CreateReport from './pages/CreateReport';
import VocReport from './pages/VocReport';
import Settings from './pages/Settings';
import Layout from './components/Layout/Layout';

function App() {
  const [userContext, setUserContext] = useContext(UserContext);
  const location = useLocation();

  const verifyUser = useCallback(() => {
    const url = process.env.REACT_APP_API_URL + '/users/refreshtoken';
    const body = {};
    const config = { withCredentials: true };

    axios.post(url, body, config)
      .then(res => {
        setUserContext(userContext => ({ ...userContext, ...res.data }));
      })
      .catch(err => {
        // Do nothing
      });
  }, [setUserContext]);

  useEffect(() => {
    verifyUser();
  }, [verifyUser]);

  useEffect(() => {
    if (userContext.lastReloaded
      && userContext.lastUpdated
      && new Date(userContext.lastReloaded) < new Date(userContext.lastUpdated)
      && new Date(userContext.lastUpdated) < new Date()
    ) {
      window.location.reload();
    }
  }, [location, userContext]);

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline enableColorScheme />
      <Routes>
        {!userContext.token ?
          <Route>
            <Route index element={<Login />} />
            <Route path='*' element={null} />
          </Route>
          :
          <Route element={<Layout />}>
            <Route index element={<Navigate to='dashboard' />} />
            <Route path='dashboard' element={<Dashboard />} />
            <Route path='checkout' element={<CheckOut />} />
            <Route path='addstock' element={<AddStock />} />
            <Route path='inventory' element={<Inventory />} />
            <Route path='projects' element={<Projects />} />
            <Route path='createreport' element={<CreateReport />} />
            <Route path='vocreport' element={<VocReport />} />
            <Route path='settings' element={<Settings />} />
          </Route>
        }
      </Routes>
    </ThemeProvider>
  );
}

export default App;