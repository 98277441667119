import dayjs from "dayjs";
import axios from "axios";

export const formatCurrency = value => {
    const formatter = new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" });
    return formatter.format(Number.parseFloat(value).toFixed(2));
};

export const combineDateTime = (date, time) => {
    date = dayjs(date).format("YYYY-MM-DD");
    time = dayjs(time).format("HH:mm:ss.sssZ");
    return dayjs(`${date}T${time}`).toDate();
};

export const refreshToken = setUserContext => {
    const url = process.env.REACT_APP_API_URL + "/users/refreshtoken";
    const body = {};
    const config = { withCredentials: true };

    axios.post(url, body, config).then((res => setUserContext(userContext => ({ ...userContext, ...res.data })))).catch(err => { });
};

export const axiosInstance = axios.create();

let tokenRequest;

const resetTokenRequest = () => {
    tokenRequest = null;
};

const getToken = config => {
    if (!tokenRequest) {
        tokenRequest = new Promise((resolve, reject) => {
            const url = process.env.REACT_APP_API_URL + "/users/refreshtoken";
            const body = {};
            const configuration = { withCredentials: true };

            axios.post(url, body, configuration)
                .then(res => {
                    config.setUserContext(userContext => ({ ...userContext, ...res.data }));
                    config.headers.Authorization = "Bearer " + res.data.token;
                    return resolve(config);
                })
                .catch(err => {
                    return reject(err);
                });
        });

        tokenRequest.then(resetTokenRequest, resetTokenRequest);
    }

    return tokenRequest;
};

axiosInstance.interceptors.response.use((res) => res,
    (err) => {
        if (err.response && err.response.status === 401) {
            return getToken(err.config).then(res => {
                return axios(err.config);
            });
        } else {
            return Promise.reject(err);
        }
    }
);